import { createRef, useEffect, useReducer, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

import ReCAPTCHA from 'react-google-recaptcha';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';

const Signup = () => {
  const reducer = (state: any, value: any) => ({
    ...state,
    [value.id]: value.value,
  });

  const recaptchaRef = createRef<any>();
  const formRef = createRef<any>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [model, setModel] = useReducer(reducer, {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    organisationName: '',
  });
  const [affiliateTrackingId, setAffiliateTrackingId] = useState<string>();

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    const provider = searchParams.get('provider');
    const affiliateTrackingId = searchParams.get('atid');

    if (affiliateTrackingId) {
      setAffiliateTrackingId(affiliateTrackingId);
    }

    const error = searchParams.get('error');

    if (!!error) {
      if (error === 'unauthorised') {
        setError('The process was either cancelled or not allowed.');
        setError(`Signing up using <b>${provider || 'an external provider'}</b> was either cancelled or is not allowed.`);
      } else {
        setError(
          `Something seems to have gone wrong while trying to sign up using <b>${
            provider || 'the external provider'
          }</b>.<br /><br />Please try again.`
        );
      }

      searchParams.delete('error');
      searchParams.delete('provider');

      setSearchParams(searchParams);
    }

    const code = searchParams.get('code');

    if (!!code) {
      signup(code);

      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  const signup = async (code?: string) => {
    setShowLoader(true);

    recaptchaRef.current.reset();

    const token = await recaptchaRef.current.executeAsync();
    const result = await ApiService.getInstance().signup({
      account: {
        firstName: model.firstName,
        lastName: model.lastName,
        emailAddress: model.emailAddress,
        password: model.password,
      },
      organisation: {
        companyName: model.organisationName,
      },
      registrationCode: code,
      recaptchaToken: token,
    });

    if (!code) {
      if (result.status === 201 || result.status === 200) {
        navigate(`${PAGE_URLS.SIGNIN}?registration=success`);
        document.cookie = `logged_in_name=${model.firstName}`;
      } else {
        setError(result?.message || 'Something seems to have gone wrong.');
        setShowLoader(false);
      }
    }
  };

  const onFormInputChange = (event: any) => setModel(event.target);

  const validateEmailAddress = (value: string, callback: any) => {
    const expression =
      /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/;

    if (!expression.test(value || '')) {
      return {
        valid: false,
        message: 'Invalid email address',
      };
    }

    ApiService.getInstance()
      .verifyEmailAddress(value)
      .then((response) => {
        if (!response.data) {
          callback({
            valid: false,
            message: 'This email address is already in use',
          });
        }
      });

    return {
      valid: true,
    };
  };

  return (
    <div className='login-container container' ref={formRef}>
      <div className='row justify-content-center'>
        <div className='col-11 col-sm-12 col-md-9 col-lg-7 col-xl-6'>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-2 text-nowrap light-text'>
                Sign up to <b>Business2Cloud</b>
              </span>
            </div>
          </div>
          <div className='card card-sm'>
            <div className='card-body'>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-icons-two-tone' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={signup}>
                    <FormSection>
                      <div className='form-section-header'>
                        <h5>Personal</h5>
                        <span>Tell us about yourself.</span>
                      </div>
                      <div className='grid grid-col-1/2'>
                        <FormInput id='firstName' placeholder='First name' required={true} value={model.firstName} onChange={onFormInputChange} />
                        <FormInput id='lastName' placeholder='Last name' required={true} value={model.lastName} onChange={onFormInputChange} />
                      </div>
                      <FormInput
                        id='emailAddress'
                        placeholder='Email address'
                        required={true}
                        value={model.emailAddress}
                        onChange={onFormInputChange}
                        validate={validateEmailAddress}
                      />
                      <div className='grid grid-col-1/2'>
                        <FormInput
                          id='password'
                          placeholder='Password'
                          type='password'
                          required={true}
                          value={model.password}
                          onChange={onFormInputChange}
                        />
                        <FormInput
                          id='confirmPassword'
                          placeholder='Confirm password'
                          type='password'
                          required={true}
                          value={model.confirmPassword}
                          match='password'
                          onChange={onFormInputChange}
                        />
                      </div>
                    </FormSection>
                    <FormSection>
                      <div className='form-section-header'>
                        <h5>Organisation</h5>
                        <span>Tell us about your organisation.</span>
                      </div>
                      <FormInput
                        id='organisationName'
                        placeholder='Organisation name'
                        required={true}
                        value={model.organisationName}
                        onChange={onFormInputChange}
                      />
                      <div className='d-flex flex-row mt-3'>
                        {process.env.REACT_APP_SIGN_IN_WITH_XERO === 'true' ||
                          (affiliateTrackingId && (
                            <iframe
                              className='me-auto'
                              title='Sign up with Xero'
                              src={`/signup-with-xero.html?align=left&api=${process.env.REACT_APP_B2C_API_URL}${
                                affiliateTrackingId ? `&atid=atid:${affiliateTrackingId}` : ''
                              }`}
                              style={{ height: 52, margin: -8 }}
                              sandbox='allow-scripts allow-top-navigation'></iframe>
                          ))}
                        <button className='btn btn-sm btn-secondary ms-auto' type='submit'>
                          Sign up
                        </button>
                      </div>
                    </FormSection>
                  </Form>
                  <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string} />
                </div>
              </div>
            </div>
            {showLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
          <div className='d-flex flex-column mt-4 mb-5 gap-2'>
            <span className='d-flex justify-content-center'>
              Already registered?&nbsp;<Link to='/signin'>Sign in</Link>&nbsp;now.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signup);
