import React from 'react'
import { withRouter } from "../routes/withRouter";

const LinkSubAreasAreas = (props: any) => {

  return (
      <div>
         LinkSubAreasAreas
      </div>
  );
}

export default withRouter(LinkSubAreasAreas);
