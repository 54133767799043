// store types

// reducer action types
export enum REDUCER_ACTION_SET {
  SET_JWT_TOKEN = 'reducer_action_set_jwt_token',
  SET_ENTITIES = 'reducer_action_set_entities',
  SET_ORGANISATIONS = 'reducer_action_set_organisations',
  SET_ORGANISATION_SELECTED = 'reducer_action_set_organisation_selected',
  SET_AREAS = 'reducer_action_set_areas',
  SET_SUB_AREAS = 'reducer_action_set_sub_areas',
  SET_VERSIONS = 'reducer_action_set_versions',
  SET_HELPER_TABLES = 'reducer_action_set_helper_tables',
  SET_USER = 'reducer_action_set_user',
  SET_BREAD_CRUMB = 'bread_crumb',
  SET_CONNECTOR_TYPES = 'reducer_action_set_connector_types',
  SET_ORGANISATION_CONNECTOR_TYPES = 'reducer_action_set_organisation_connector_types',
  TRIGGER_CONNECTOR_TYPES_CACHE_BUST = 'TRIGGER_CONNECTOR_TYPES_CACHE_BUST',
}
