import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { Route } from 'react-router-dom';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const STATUS_TRANSLATE = {
  active: 'active',
  locked: 'lock',
  archived: 'archive',
  lock: 'locked',
  archive: 'archived',
};

const Areas = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const deleteEnabled = store.user && store.user.role && store.user.role.toLowerCase() === 'superadmin' ? true : false;

  const [areas, setAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>({});
  const [modalShow, setModalShow] = React.useState<any>(false);

  const getAreas = async () => {
    let areasFetched = await api.getAreas(store?.organisationSelected?.organisationId);
    if (areasFetched) {

      // sort areas
      const newValues = { ...values };
      areasFetched = sortList(areasFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListAreaMainDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setAreas(areasFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_AREAS,
        payload: areasFetched,
      };
      dispatch(storeAction);
    }
  };

  const getStatuses = async () => {
    const statusesFetched = await api.getStatuses();
    if (statusesFetched && Array.isArray(statusesFetched)) {
      const statusOptions: any = {};
      statusesFetched.forEach((status) => {
        if (status.Name && status.Id) {
          statusOptions[status.Name] = status;
        }
      });

      const newValues = { ...values };
      newValues['statusOptions'] = statusOptions;
      setValues(newValues);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getAreas();
      getStatuses();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Areas',
      });
    })();
  }, [store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const areaAdd = async () => {
    const result = await api.createArea(values.areaName, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs

    getAreas();
    setModalShow(false);
  };

  const areaAddMultiple = async () => {
    const tables = values.tables.split('\n');
    let failedItems = '';
    const errors = [];
    for (let i = 0; i < tables.length; i++) {
      // const table = tables[0].split(',');
      const table = tables[i].trim();
      //const name = table.length > 0 ? table[0] : '';
      const name = table;
      const result = await api.createArea(name, s.store?.organisationSelected?.organisationId);
      if (result && result.statusCode) {
        failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
        failedItems += name;
        result.message && errors.push(result.message);
      }
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['tables'] = failedItems;
      newValues['errors'] = errors;
      setValues(newValues);
    } else {
      getAreas();
      setModalShow(false);
    }
  };

  const areaEdit = async () => {
    const statusId =
      values.status &&
      STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive'] &&
      values.statusOptions &&
      values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']] &&
      values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        ? values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        : null;

    const result = await api.updateArea(values.areaName, s.store?.organisationSelected?.organisationId, values.editArea.Id, statusId); // TODO handle multiple orgs

    let failedItems = '';
    const errors = [];
    if (result && result.statusCode) {
      failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
      failedItems += values.subAreaName;
      result.message && errors.push(result.message);
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['tables'] = failedItems;
      newValues['errors'] = errors;
      setValues(newValues);
    } else {
      getAreas();
      setModalShow(false);
    }
  };

  const areaDelete = async (name: string, areaId: string) => {
    const result = await api.deleteArea(name, s.store?.organisationSelected?.organisationId, areaId); // TODO handle multiple orgs

    getAreas();
  };

  const addPopup = async () => {
    const newValues = { ...values };

    newValues.areaName = '';
    newValues.editUserFlag = false;
    newValues.tables = '';
    newValues.errors = null;

    setValues(newValues);
    setModalShow(true);
  };

  const editPopup = async (area: any) => {
    const newValues = { ...values };

    newValues.areaName = area.Name;
    newValues.editArea = area;
    newValues.editUserFlag = true;
    newValues.errors = null;
    newValues.status =
      area.Status && area.Status.Name && STATUS_TRANSLATE[(area.Status.Name as 'active') || 'locked' || 'archived']
        ? STATUS_TRANSLATE[(area.Status.Name as 'active') || 'locked' || 'archived']
        : STATUS_TRANSLATE['active'];

    setValues(newValues);
    setModalShow(true);
  };

  const sortListAreas = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(areas, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListAreaMainDirection = sortDirection;
    setValues(newValues);
    setAreas(sortedList as any);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        <h3 className='page-heading has-tools mb-4'>
          Areas
          <div className='tools'>
            <button
              className='btn btn-secondary fw-bold'
              onClick={() => {
                addPopup();
              }}>
              <i className='fa-solid fa-plus' />
              Add new Area
            </button>
          </div>
        </h3>

        <div className='card card--height-limit'>
          <table className='table table--no-head-border'>
            <colgroup>
              <col style={{ width: '60%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr className='table-header'>
                <th style={{cursor: 'pointer'}}>
                <div style={{display: 'inline-block'}}
                  onClick={(e) => {
                    sortListAreas(values.sortListAreaMainDirection === undefined || values.sortListAreaMainDirection === SORT_DIRECTION.ASC
                      ? SORT_DIRECTION.DEC 
                      : SORT_DIRECTION.ASC
                    );
                }}>
                  Name
                  <i className={values.sortListAreaMainDirection === undefined || values.sortListAreaMainDirection === SORT_DIRECTION.ASC 
                    ? 'fa-solid fa-arrow-down-short-wide ms-1'
                    : 'fa-solid fa-arrow-up-wide-short ms-1'
                  } 
                  />
                </div>
                <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                  <input
                    className='form-control form-control-sm'
                    type='text'
                    id='filterArea'
                    name='filterArea'
                    placeholder='Search'
                    value={values.filterArea}
                    onChange={(e) => handleChange(e, 'filterArea')}
                  />
                </div>
                </th>
                <th>Status</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {areas.map((area: any, index) => {
                if (values.filterArea && values.filterArea !== '' && area.Name.toLowerCase().indexOf(values.filterArea.toLowerCase()) === -1) {
                  return <Fragment></Fragment>;
                }
                return (
                  <tr key={index}>
                    <td>
                      <span>{area.Name}</span>
                    </td>
                    <td>
                      <div>
                        {(() => {
                          if (area.Status && area.Status.Name === 'active') {
                            return <span className='status-label active'>ACTIVE</span>;
                          } else if (area.Status && area.Status.Name === 'locked') {
                            return <span className='status-label locked'>LOCKED</span>;
                          } else if (area.Status && area.Status.Name === 'archived') {
                            return <span className='status-label archived'>ARCHIVED</span>;
                          } else {
                            return <span className='status-label active'>ACTIVE</span>;
                          }
                        })()}
                      </div>
                    </td>
                    <td>
                      <div className='tools'>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => {
                            editPopup(area);
                          }}>
                          <i className='fa-solid fa-pen-to-square' />
                          Edit
                        </button>
                        {deleteEnabled && (
                          <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => {
                              areaDelete(area.Name, area.Id);
                            }}>
                            <i className='fa-solid fa-trash-can' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {modalShow && !values.editUserFlag && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Add new Area</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShow(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='milestoneName' className='label--has-info'>
                        Name(s)
                        <small className='alert alert-success p-2'>
                          <i className='fa-solid fa-circle-info me-2' />
                          Add multiple items by separating by a new line
                        </small>
                      </label>
                      <textarea onChange={(e) => handleChange(e, 'tables')} placeholder='name' className='form-control' value={values.tables} />
                      {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                        <div className='mt-2'>
                          <label className='label--has-info'>
                            <small className='alert alert-danger p-2'>
                              <i className='fa-solid fa-exclamation-circle me-2' />
                              <div className='float-end'>
                                {values.errors.map((message: any) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            </small>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='modal-footer'>
                    <button
                      className='btn btn-success'
                      onClick={() => {
                        areaAddMultiple();
                      }}>
                      <i className='fa-solid fa-check' />
                      Add
                    </button>

                    <button
                      className='btn btn-outline-dark'
                      onClick={() => {
                        setModalShow(false);
                      }}>
                      <i className='fa-solid fa-xmark' />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}

        {modalShow && values.editUserFlag && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{values.editUserFlag ? 'Edit Area' : 'Add new Area'}</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShow(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='areaName'>Area Name</label>
                      <input
                        className='form-control'
                        type='text'
                        id='areaName'
                        name='areaName'
                        placeholder='Add Area'
                        value={values.areaName}
                        onChange={(e) => handleChange(e, 'areaName')}
                      />
                      {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                        <div className='mt-2'>
                          <label className='label--has-info'>
                            <small className='alert alert-danger p-2'>
                              <i className='fa-solid fa-exclamation-circle me-2' />
                              <div className='float-end'>
                                {values.errors.map((message: any) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            </small>
                          </label>
                        </div>
                      )}
                      <label className='mt-2'>Set Status</label>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='active'
                          name='status'
                          className='form-check-input'
                          value={'active'}
                          checked={values.status === 'active'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'active'}>
                          Active
                        </label>
                      </div>
                      {/* <div className="form-check">
                                  <input
                                      autoComplete="off"
                                      type="radio"
                                      id="lock"
                                      name="status"
                                      className="form-check-input"
                                      value={"lock"}
                                      checked={values.status === "lock"}
                                      onChange={(e) => {
                                          handleChange(e, 'status');
                                      }}
                                  />
                                  <label className="form-check-label" htmlFor={'lock'}>Lock</label>
                                </div> */}
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='archive'
                          name='status'
                          className='form-check-input'
                          value={'archive'}
                          checked={values.status === 'archive'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'archive'}>
                          Archive
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      className='btn btn-success'
                      onClick={() => {
                        if (values.editUserFlag) {
                          areaEdit();
                        } else {
                          areaAdd();
                        }
                      }}>
                      <i className='fa-solid fa-check' />
                      Add
                    </button>

                    <button
                      className='btn btn-outline-dark'
                      onClick={() => {
                        setModalShow(false);
                      }}>
                      <i className='fa-solid fa-xmark' />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}

        <div></div>
      </div>
    </div>
  );
};

export default withRouter(Areas);
