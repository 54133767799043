import { useEffect, useState } from 'react';

const Toggle = ({ value, onChange }: { value: boolean; onChange?: (value: boolean) => void }) => {
  const [_value, setValue] = useState(value);

  const _onChange = () => {
    setValue(!_value);
    onChange && onChange(!_value);
  };

  return (
    <span className={`toggle-control ${_value ? 'on' : 'off'}`} onClick={_onChange}>
      <span className='toggle-control-track'>
        <span className='toggle-control-thumb'></span>
      </span>
    </span>
  );
};

export default Toggle;
