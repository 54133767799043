import { ChangeEvent, useContext, useState, KeyboardEvent, createRef } from 'react';
import { Store } from '../store/Store';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';

import FormSection from './FormSection';
import Form from './Form';
import FormInput from './FormInput';

const Profile = () => {
  const formRef = createRef<any>();
  const storeContext = useContext(Store);
  const store = storeContext.store;
  const user = store?.user;

  const [requireMultiFactorAuthentication, setRequireMultiFactorAuthentication] = useState(user.requireMultiFactorAuthentication);
  const [verificationDevices, setVerificationDevices] = useState(user.verificationDevices || []);
  const [showMFAConfirmation, setShowMFAConfirmation] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationReference, setVerificationReference] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  const dispatchUser = (requireMultiFactorAuthentication: boolean) => {
    storeContext.dispatch({
      type: REDUCER_ACTION_SET.SET_USER,
      payload: {
        ...user,
        requireMultiFactorAuthentication: requireMultiFactorAuthentication,
        verificationDevices: verificationDevices,
      },
    });
  };

  const onVerificationCodeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.currentTarget.value);
  };

  const onVerificationCancelClick = () => {
    setShowMFAConfirmation(false);
    setVerificationCode('');
    setVerificationReference(undefined);
  };

  const onToggleMfaClick = async () => {
    setShowLoader(true);

    try {
      const response = await ApiService.getInstance().setMfa(!requireMultiFactorAuthentication, verificationReference, verificationCode);

      if (response.errorMessage) {
        setError(response.errorMessage);
        setShowLoader(false);
        return;
      }

      if (response.verificationRequired) {
        setVerificationReference(response.verificationReference);
        setShowMFAConfirmation(true);
      } else {
        setVerificationDevices([]);

        if (!requireMultiFactorAuthentication && response.verificationDevices) {
          setVerificationDevices(response.verificationDevices);
        }

        setRequireMultiFactorAuthentication(!requireMultiFactorAuthentication);
        dispatchUser(true);
        setShowMFAConfirmation(false);
        setVerificationCode('');
        setVerificationReference(undefined);
      }
    } catch (error) {
      setError(error as string);
    }

    setShowLoader(false);
  };

  const onDeleteVerificationDeviceClick = async (reference: string) => {
    setShowLoader(true);

    await ApiService.getInstance().deleteMfaDevice(reference);

    const deviceIndex = verificationDevices.findIndex((_: any) => _.Reference == reference);

    verificationDevices.splice(deviceIndex, 1);

    setShowLoader(false);
  };

  return (
    <div>
      {showMFAConfirmation && (
        <div className='modal modal-sm'>
          <div className='modal-dialog modal-dialog-centered col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
            <div className='modal-content'>
              <div className='modal-body' ref={formRef}>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-icons-two-tone' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <Form>
                  <FormSection>
                    <div className='form-section-header'>
                      <h5>Verification code</h5>
                      <span>
                        Provide the verification code that was sent to your registered email address to continue{' '}
                        <b>{requireMultiFactorAuthentication ? 'disabling' : 'enabling'}</b> multi-factor authentication for your account.
                      </span>
                    </div>
                    <FormInput
                      id='verificationCode'
                      placeholder='Verification code'
                      required={true}
                      value={verificationCode}
                      onChange={onVerificationCodeInputChange}
                    />
                    <div className='d-flex flex-row gap-2 justify-content-end mt-3'>
                      <button className='btn btn-sm btn-outline-secondary' onClick={onVerificationCancelClick}>
                        Cancel
                      </button>
                      {requireMultiFactorAuthentication ? (
                        <button type='submit' className='btn btn-sm btn-danger' onClick={onToggleMfaClick}>
                          Disable
                        </button>
                      ) : (
                        <button type='submit' className='btn btn-sm btn-primary' onClick={onToggleMfaClick}>
                          Enable
                        </button>
                      )}
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show' />
        </div>
      )}
      <div>
        <div className='page-sub-heading'>
          <h4>User details</h4>
        </div>
        <div className='fancy-list fancy-list__vertical col-12 col-lg-8 col-xl-5'>
          <div>
            <label>Email Address</label>
            <span>{user.email}</span>
          </div>
          <div>
            <label>First Name</label>
            <span>{user.firstName}</span>
          </div>
          <div>
            <label>Last Name</label>
            <span>{user.lastName}</span>
          </div>
        </div>
        <div className='page-divider'></div>
        <div className='page-sub-heading'>
          <h4>Additional Security</h4>
        </div>
        <div className='fancy-list fancy-list__vertical col-12 col-lg-8 col-xl-5'>
          <div className='flex flex-row'>
            <div>
              <label>Multi-factor Authentication</label>
              <span className='d-flex me-auto align-items-center'>
                {user.requireMultiFactorAuthentication ? 'Has been enabled' : 'Has not been enabled'}
              </span>
            </div>
            {requireMultiFactorAuthentication ? (
              <button className='ms-auto btn btn-sm btn-danger' onClick={onToggleMfaClick}>
                Disable
              </button>
            ) : (
              <button className='ms-auto btn btn-sm btn-secondary' onClick={onToggleMfaClick}>
                Enable
              </button>
            )}
          </div>
          <div>
            <label>Devices</label>
            {verificationDevices.map((verificationDevice: any, index: number) => (
              <div key={verificationDevice.Reference} className='flex flex-row align-items-center gap-2  inset'>
                <span className='flex flex-row align-items-center gap-2'>
                  {verificationDevice.Description}
                  {verificationDevice.Trusted && (
                    <span className='material-icons-two-tone pending' title='Trusted device'>
                      verified
                    </span>
                  )}
                </span>
                <button className='ms-auto btn btn-xs btn-round btn-outline-danger'>
                  <span className='material-icons-two-tone' onClick={() => onDeleteVerificationDeviceClick(verificationDevice.Reference)}>
                    delete
                  </span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showLoader && (
        <div className='dashboard__content__loader'>
          <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
            <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Profile;
