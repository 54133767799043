import { useEffect, useState } from 'react';

import Toggle from './Toggle';
import Business2CloudApiService from '../services/Business2CloudApiService';

const GlobalConfiguration = () => {
  const [globalConfiguration, setGlobalConfiguration] = useState([]);

  useEffect(() => {
    Business2CloudApiService.Instance.getAdminGlobalConfiguration().then((response) => setGlobalConfiguration(response));

    setInterval(() => {
      Business2CloudApiService.Instance.getAdminGlobalConfiguration().then((response) => setGlobalConfiguration(response));
    }, 10000);
  }, []);

  const onGlobalConfigurationChanged = async (key: string, value: any) => {
    await Business2CloudApiService.Instance.setAdminGlobalConfiguration(key, value.toString());
  };

  return (
    <>
      <div className='page-sub-heading'>
        <h4>General</h4>
      </div>
      <div className='fancy-list fancy-list__vertical col-12 col-lg-8 col-xl-6'>
        <div className='disabled'>
          {globalConfiguration.map((configuration: any) => {
            const value = JSON.parse(configuration.Value);

            return (
              <div className='d-flex flex-row' key={configuration.Key}>
                <span className='d-flex me-auto align-items-center'>{configuration.Key.split(/(?=[A-Z])/).join(' ')}</span>
                <Toggle value={value} onChange={(value: boolean) => onGlobalConfigurationChanged(configuration.Key, value)} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GlobalConfiguration;
