import React from 'react'
import { withRouter } from "../routes/withRouter";

const AssignHelperTablesUsers = (props: any) => {

  return (
      <div>
         AssignHelperTablesUsers
      </div>
  );
}

export default withRouter(AssignHelperTablesUsers);
