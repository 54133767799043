import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { PAGE_URLS } from '../routes/routes';
import { forEachTrailingCommentRange } from 'typescript';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const AssignUsersToSecurityGroups = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [users, setUsers] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  //const [areas, setAreas] = React.useState([]);
  //const [subAreas, setSubAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>({});

  const getUsers = async () => {
    let usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);
    if (usersFetched) {
      // sort users
      const newValues = { ...values };
      usersFetched = sortList(usersFetched, ['FirstName'], SORT_DIRECTION.ASC);
      newValues.sortListUsersDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setUsers(usersFetched);
      // const storeAction = {
      //   type: REDUCER_ACTION_SET.SET_AREAS,
      //   payload: areasFetched,
      // };
      // dispatch(storeAction);
    }
  };

  const getGroups = async () => {
    let rolesFetched = await api.getGroups(s.store?.organisationSelected?.organisationId);
    if (rolesFetched) {
      // sort groups
      const newValues = { ...values };
      rolesFetched = sortList(rolesFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListGroupsDirection = SORT_DIRECTION.ASC;
      setValues(newValues);
      setGroups(rolesFetched);
    }
  };

  // const getAreas = async () => {
  //   const areasFetched = await api.getAreas();
  //   if (areasFetched) {
  //     setAreas(areasFetched);
  //     const storeAction = {
  //       type: REDUCER_ACTION_SET.SET_AREAS,
  //       payload: areasFetched,
  //     };
  //     dispatch(storeAction);
  //   }
  // }

  // const getSubAreas = async () => {
  //   const subAreasFetched = await api.getSubAreas();
  //   if (subAreasFetched) {
  //     setSubAreas(subAreasFetched);
  //     const storeAction = {
  //       type: REDUCER_ACTION_SET.SET_SUB_AREAS,
  //       payload: subAreasFetched,
  //     };
  //     dispatch(storeAction);
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getUsers();
      getGroups();
      //getAreas();
      //getSubAreas();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Assign Users to Security Groups',
      });
    })();
  }, [s.store?.organisationSelected?.organisationId]);

  enum ValueStoreTypes {
    STRING,
    ARRAY,
  }

  const handleChange = (e: any, names: string[] | string, valueStoreType?: ValueStoreTypes) => {
    const newValues = { ...values };

    if (!Array.isArray(names)) {
      names = [names];
    }

    let newValuesStore = newValues;

    if (names[0] === 'userEdit') {
      newValues.editAddShow = true;
      newValues.groupMappings = { groups: {} };
      newValues.groupMappings.userId = e.target.value.Id;
      users &&
        Array.isArray(users) &&
        users.forEach((user: any) => {
          newValues.groupMappings.groups[user.Id] = {};
          newValues.groupMappings.groups[user.Id].checked = {};
          user.Groups &&
            Array.isArray(user.Groups) &&
            user.Groups.forEach((group: any) => {
              newValues.groupMappings.groups[user.Id].checked[group.Id] = true;
            });
        });
    }

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      if (!newValuesStore[name]) {
        newValuesStore[name] = {};
      }
      newValuesStore = newValuesStore[name];
    }

    if (valueStoreType === ValueStoreTypes.ARRAY && !Array.isArray(newValuesStore[names[names.length - 1]])) {
      newValuesStore[names[names.length - 1]] = [];
    }
    if (valueStoreType === ValueStoreTypes.ARRAY) {
      newValuesStore[names[names.length - 1]].push(e.target.value);
    } else {
      newValuesStore[names[names.length - 1]] = e.target.value;
    }

    setValues(newValues);
  };

  const unAssign = async (userId: string) => {
    await api.deleteLinkUserToGroup(userId, s.store?.organisationSelected?.organisationId);
    getUsers();
    getGroups();
  };

  const submit = async () => {
    //const result = await api.addGroup(values.firstName, s.store.organisations[0].Id); // TODO handle multiple orgs
    //const groupId = result.Id;

    if (!values.groupMappings || !values.groupMappings.groups) return;

    //addPermissionAreaSubArea
    const userIdKeys = Object.keys(values.groupMappings.groups);

    for (let i = 0; i < userIdKeys.length; i++) {
      const userIdKey = userIdKeys[i];
      await api.deleteLinkUserToGroup(userIdKey, s.store?.organisationSelected?.organisationId);
      //const groupData = values.groupMappings.groups[userIdKey];

      const groupKeys = Object.keys(values.groupMappings.groups[userIdKey].checked);

      for (let j = 0; j < groupKeys.length; j++) {
        if (values.groupMappings.groups[userIdKey].checked[groupKeys[j]] === true) {
          const result = await api.linkUserToGroup(userIdKey, groupKeys[j], s.store?.organisationSelected?.organisationId);
        }
      }
    }
    getUsers();
    getGroups();
    setValues({ ...values, editAddShow: false });
  };

  const sortListUsers = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(users, ['FirstName'], sortDirection);

    const newValues = { ...values };
    newValues.sortListUsersDirection = sortDirection;
    setValues(newValues);
    setUsers(sortedList as any);
  };

  const groupMappings = () => {
    return (
      <div className='row justify-content-center'>
        <div className='col-11'>
          {!values.editAddShow ? (
            <Fragment>
              <h3 className='page-heading has-tools mb-4 d-none1'>
                Assign Users to Security Groups
                <div className='tools'>
                  <button
                    className='btn btn-secondary fw-bold'
                    onClick={() => {
                      const newValues = { ...values };
                      newValues.editAddShow = true;
                      setValues(newValues);
                    }}>
                    <i className='fa-solid fa-plus' />
                    Create new assignment
                  </button>
                </div>
              </h3>

              <div className='card d-none1'>
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item' style={{ borderBottom: '1px solid #dee2e6' }}>
                    <h5 className='my-2'>Users assigned to Security Groups</h5>
                  </li>
                </ul>
                <div className='card-body card-body--height-limit' style={{ maxHeight: 'calc(100vh - 332px)' }}>
                  <table className='table table--no-head-border'>
                    <colgroup>
                      <col style={{ width: '80%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <thead>
                      <tr className='table-header'>
                        <th style={{cursor: 'pointer'}}>
                          <div style={{display: 'inline-block'}}
                            onClick={(e) => {
                              sortListUsers(values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                                ? SORT_DIRECTION.DEC 
                                : SORT_DIRECTION.ASC
                              );
                          }}>  
                            Name 
                            <i className={values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC 
                              ? 'fa-solid fa-arrow-down-short-wide ms-1'
                              : 'fa-solid fa-arrow-up-wide-short ms-1'
                            } />
                          </div>
                          <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                            <input
                              className='form-control form-control-sm'
                              type='text'
                              id='filterSecurityUserGroup'
                              name='filterSecurityUserGroup'
                              placeholder='Search'
                              value={values.filterHelperTable}
                              onChange={(e) => handleChange(e, 'filterSecurityUserGroup')}
                            />
                          </div>
                        </th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user: any) => {
                        if (values.filterSecurityUserGroup && values.filterSecurityUserGroup !== '' && `${user.FirstName} ${user.LastName}`.toLowerCase().indexOf(values.filterSecurityUserGroup.toLowerCase()) === -1) {
                          return <Fragment></Fragment>;
                        }
                        return (
                          <tr>
                            <td className='tools'>
                              <span>
                                {user.FirstName} {user.LastName}
                              </span>
                              <span className='status-label'>
                                <i className='fa-solid fa-user-plus' />
                                ASSIGNED TO
                              </span>
                              {user.Groups.map((group: any, index: number) => {
                                return (
                                  <span>
                                    {index === 0 ? '' : ', '}
                                    {group.Name}
                                  </span>
                                );
                              })}
                            </td>
                            <td>
                              <div className='tools'>
                                <button
                                  className='btn btn-outline-primary btn-sm'
                                  onClick={() => {
                                    handleChange({ target: { value: user } }, 'userEdit');
                                  }}>
                                  <i className='fa-solid fa-pen-to-square' />
                                  Edit
                                </button>
                                <button
                                  className='btn btn-outline-danger btn-sm'
                                  onClick={() => {
                                    unAssign(user.Id);
                                  }}>
                                  <i className='fa-solid fa-user-slash' />
                                  Unassign
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='d-none' style={{ height: '150px' }} />
            </Fragment>
          ) : (
            <Fragment>
              <h3 className='page-heading has-tools mb-4'>
                Assign Users to Security Groups
                <div className='tools'>
                  <button
                    className='btn btn-outline-secondary fw-bold'
                    onClick={() => {
                      handleChange({ target: { value: false } }, 'editAddShow');
                    }}>
                    <i className='fa-solid fa-arrow-left' />
                    Back to assignments
                  </button>
                </div>
              </h3>

              <div className='card card--height-limit no-overflow'>
                <div className='card__linking-container p-4'>
                  <div className='card__linking-container--left card-body border p-0'>
                    <div className='p-3 pb-0'>
                      <div className='has-tools'>
                        <h5 className='mb-0'>Users</h5>
                        <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                          onClick={(e) => {
                            sortListUsers(values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                              ? SORT_DIRECTION.DEC 
                              : SORT_DIRECTION.ASC
                            );
                          }}
                          >
                            {values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                              ? 'Ascending' 
                              : 'Descending'
                            }
                            <i className={values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC 
                              ? 'fa-solid fa-arrow-down-short-wide ms-1'
                              : 'fa-solid fa-arrow-up-wide-short ms-1'
                            } />
                        </div>
                      </div>
                      <input
                        type='text'
                        className='form-control form-control-sm my-3'
                        placeholder='Search Users'
                        onChange={(e) => {
                          handleChange(e, 'filterUsers');
                        }}
                      />
                    </div>
                    <hr className='my-4 mb-0' />
                    <div className='form-check__container form-check__container--left'>
                      {users.map((user: any) => {
                        if (
                          values.filterUsers &&
                          values.filterUsers !== '' &&
                          `${user.FirstName} ${user.LastName}`.toLowerCase().indexOf(values.filterUsers.toLowerCase()) === -1
                        ) {
                          return <Fragment></Fragment>;
                        }

                        return (
                          <div className='form-check'>
                            <input
                              autoComplete='off'
                              type='radio'
                              id={'user' + user.Id}
                              name={'user'}
                              className='form-check-input'
                              value={user.Id}
                              checked={values.groupMappings && values.groupMappings?.userId === user.Id}
                              onChange={(e) => {
                                handleChange(e, ['groupMappings', 'userId']);
                              }}
                            />
                            <label className='form-check-label' htmlFor={'user' + user.Id}>
                              {user.FirstName} {user.LastName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center'>
                    <i className='fa-solid fa-arrow-right' />
                  </div>

                  <div className='card__linking-container--right card-body border p-0'>
                    {/* <div className="p-3 pb-0">
                  <div className="has-tools">
                    <h5 className="mb-0">Security Groups</h5>
                    <div className="btn btn-outline-secondary btn-sm">Ascending<i className="fa-solid fa-arrow-down-short-wide ms-1" /></div>
                  </div>
                  <input type="text" className="form-control form-control-sm my-3" placeholder="Search Security Groups"/>

                  <div className="form-check">
                    <input type="radio" id="AllCurrentAndFuture" name="SecGroupSelection" className="form-check-input"/>
                    <label htmlFor="AllCurrentAndFuture" className="form-check-label">All Current and Future</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="AllCurrent" name="SecGroupSelection" className="form-check-input"/>
                    <label htmlFor="AllCurrent" className="form-check-label">All Current</label>
                  </div>
                  <div className="form-check">
                    <input type="checkbox" id="SelectAll" className="form-check-input"/>
                    <label htmlFor="SelectAll" className="form-check-label">Select All</label>
                  </div>
                </div> 
                <hr className="my-4 mb-0" /> */}
                    <div className='form-check__container form-check__container--right'>
                      {users.map((user: any) => {
                        if (!values.groupMappings || values.groupMappings?.userId !== user.Id) return;
                        if (!Array.isArray(groups)) return;

                        const groupBoxes: any[] = [];

                        groups.forEach((group: any) => {
                          groupBoxes.push(
                            <div className='form-check'>
                              <input
                                autoComplete='off'
                                type='checkbox'
                                className='form-check-input'
                                id={'groups' + user.Id + group.Id}
                                checked={
                                  values.groupMappings &&
                                  values.groupMappings.groups &&
                                  values.groupMappings.groups[`${user.Id}`] &&
                                  values.groupMappings.groups[`${user.Id}`].checked &&
                                  values.groupMappings.groups[`${user.Id}`].checked[group.Id]
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleChange(
                                    {
                                      target: { value: e.target.checked },
                                    },
                                    ['groupMappings', 'groups', `${user.Id}`, 'checked', group.Id]
                                  );
                                }}
                              />
                              <label className='form-check-label' htmlFor={'groups' + user.Id + group.Id}>
                                {group.Name}
                              </label>
                            </div>
                          );
                        });

                        return groupBoxes;
                      })}
                    </div>
                  </div>
                </div>
                <div className='text-end p-4 pt-0'>
                  <button
                    className='btn btn-success fw-bold d-inline'
                    onClick={() => {
                      submit();
                    }}>
                    Assign
                    <i className='fa-solid fa-check ms-2' />
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  // TODO: Iwan - Can't get this button inside div.card. Below return should be on line 336
  return <div>{groupMappings()}</div>;
};

export default withRouter(AssignUsersToSecurityGroups);
