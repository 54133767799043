import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { PAGE_URLS } from '../routes/routes';
import { forEachTrailingCommentRange } from 'typescript';
import { SECURITY_SCREENS } from './SecurityGroups';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const GroupNewArea = (props: any) => {
  const { groupName, groupSelected, back, next, valuesInit } = props;

  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [users, setUsers] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [subAreas, setSubAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>(valuesInit);
  const [mappingCount, setMappingCount] = React.useState<number>(0);

  const getUsers = async () => {
    const usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);
    if (usersFetched) {
      setUsers(usersFetched);
      // const storeAction = {
      //   type: REDUCER_ACTION_SET.SET_AREAS,
      //   payload: areasFetched,
      // };
      // dispatch(storeAction);
    }
  };

  const getAreas = async () => {
    let areasFetched = await api.getAreas(store?.organisationSelected?.organisationId);
    if (areasFetched) {

      // sort areas
      const newValues = { ...values };
      areasFetched = sortList(areasFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListAreaDirection = SORT_DIRECTION.ASC;
      // sort sub areas
      areasFetched.forEach((area: any) => {
        if (!Array.isArray(area.Subareas)) return;
        const sortedList = sortList(area.Subareas, ['Name'], SORT_DIRECTION.ASC);
        area.Subareas = sortedList;
      });
      newValues.sortListSubAreaDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setAreas(areasFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_AREAS,
        payload: areasFetched,
      };
      dispatch(storeAction);
    }
  };

  const getSubAreas = async () => {
    const subAreasFetched = await api.getSubAreas(store?.organisationSelected?.organisationId);
    if (subAreasFetched) {
      setSubAreas(subAreasFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_SUB_AREAS,
        payload: subAreasFetched,
      };
      dispatch(storeAction);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getAreas();
      getSubAreas();
    })();
  }, []);

  useEffect(() => {
    if (
      !valuesInit ||
      !valuesInit.groupSelected ||
      !valuesInit.groupSelected.PermissionGroupAreasSubAreas ||
      valuesInit.screenPrev !== SECURITY_SCREENS.GROUP_LIST
    )
      return;

    const groupSelected = valuesInit.groupSelected;

    const newGroupMappings: any = {
      groupMappings: {
        subArea: {},
        areaFlipped: {},
        areaGlobal: {},
      },
    };
    groupSelected.PermissionGroupAreasSubAreas.forEach((permission: any, i: number) => {
      if (i === 0) {
        newGroupMappings.groupMappings['area'] = permission.B2SAreaId;
      }

      // area
      if (
        permission.B2SAreaSubarea &&
        permission.B2SAreaSubarea.AreaId &&
        !newGroupMappings.groupMappings.subArea[permission.B2SAreaSubarea.AreaId]
      ) {
        newGroupMappings.groupMappings.subArea[permission.B2SAreaId] = { checked: {} };
      }
      if (permission.B2SAreaSubarea && permission.B2SAreaSubarea.SubareaId && permission.B2SAreaSubarea.AreaId) {
        newGroupMappings.groupMappings.subArea[permission.B2SAreaSubarea.AreaId].checked[permission.B2SAreaSubarea.SubareaId] = permission
          .B2SAreaSubarea.SubareaId
          ? true
          : false;
      }
      // subarea
      // if (permission.B2SSubareaId && !newGroupMappings.groupMappings.areaFlipped[permission.B2SSubareaId]) {
      //   newGroupMappings.groupMappings.areaFlipped[permission.B2SSubareaId] = { checked: {}}
      // }
      // if (permission.B2SAreaSubarea && permission.B2SAreaSubarea.SubareaId && permission.B2SAreaSubarea.AreaId) {

      //   newGroupMappings.groupMappings.subArea[permission.B2SAreaSubarea.AreaId].checked[permission.B2SAreaSubarea.SubareaId] = permission.B2SAreaSubarea.SubareaId ? true : false;
      // }

      // subarea

      // area
      if (permission.B2SSubAreaAllCurrentAndFuture) {
        newGroupMappings.groupMappings.subArea[permission.B2SAreaId] = !newGroupMappings.groupMappings.subArea[permission.B2SAreaId]
          ? {}
          : newGroupMappings.groupMappings.subArea[permission.B2SAreaId];

        newGroupMappings.groupMappings.subArea[permission.B2SAreaId].subAreaOptions = 'allCurrentAndFuture';
      }
      if (permission.B2SAreaAllCurrentAndFuture) {
        if (!permission.B2SSubareaId) {
          newGroupMappings.groupMappings.areaGlobal.areaOptions = 'allCurrentAndFuture';
        } else if (permission.B2SSubareaId) {
          if (permission.B2SSubareaId && !newGroupMappings.groupMappings.areaFlipped[permission.B2SSubareaId]) {
            newGroupMappings.groupMappings.areaFlipped[permission.B2SSubareaId] = { checked: {} };
          }
          newGroupMappings.groupMappings.areaFlipped[permission.B2SSubareaId].areaOptions = 'allCurrentAndFuture';
        }
        //newGroupMappings.groupMappings.areaOptions = 'allCurrentAndFuture';
      }
    });

    const newValues = { ...values, ...newGroupMappings };
    //newValues.groupSelected.PermissionGroupAreasSubAreas = null;
    setValues(newValues);
  }, [groupSelected]);

  enum ValueStoreTypes {
    STRING,
    ARRAY,
  }

  const handleChange = (e: any, names: string[] | string, valueStoreType?: ValueStoreTypes) => {
    const newValues = { ...values };

    if (!Array.isArray(names)) {
      names = [names];
    }

    let newValuesStore = newValues;

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      if (!newValuesStore[name]) {
        newValuesStore[name] = {};
      }
      newValuesStore = newValuesStore[name];
    }

    if (valueStoreType === ValueStoreTypes.ARRAY && !Array.isArray(newValuesStore[names[names.length - 1]])) {
      newValuesStore[names[names.length - 1]] = [];
    }
    if (valueStoreType === ValueStoreTypes.ARRAY) {
      newValuesStore[names[names.length - 1]].push(e.target.value);
    } else {
      newValuesStore[names[names.length - 1]] = e.target.value;
    }

    setValues(newValues);
  };

  const sortListAreas = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(areas, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListAreaDirection = sortDirection;
    setValues(newValues);
    setAreas(sortedList as any);
  };

  const sortListSubArea = (sortDirection: SORT_DIRECTION) => {

    // sort sub areas
    areas.forEach((area: any) => {
      if (!Array.isArray(area.Subareas)) return;
      const sortedList = sortList(area.Subareas, ['Name'], sortDirection);
      area.Subareas = sortedList;
    });
    const newValues = { ...values };
    newValues.sortListSubAreaDirection = sortDirection;
    setValues(newValues);
    setAreas(areas as any);

  };

  

  const addGroup = async () => {
    // const result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.selectRole)
    //if (!values.groupMappings || !values.firstName) return;

    const result = await api.addGroup(values.firstName, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs

    const mappings = Object.keys(values.groupMappings);

    for (let i = 0; i < mappings.length; i++) {}
    // getUsers();
  };
  const addAdditional = async () => {
    //
    // const result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.selectRole)
    // getUsers();
    setMappingCount(mappingCount + 1);
  };

  // const submit = async () => {
  //

  //   const result = await api.addGroup(values.firstName, s.store.organisations[0].Id); // TODO handle multiple orgs
  //   const groupId = result.Id;

  //   if (!values.groupMappings || !values.groupMappings.subArea) return;

  //   //addPermissionAreaSubArea
  //   const areaIdKeys = Object.keys(values.groupMappings.subArea);

  //   for (let i = 0; i < areaIdKeys.length; i++) {
  //     const areaIdKey = areaIdKeys[i];
  //     const subAreaData = values.groupMappings.subArea[areaIdKey];

  //     if (!subAreaData.subAreaOptions || subAreaData.subAreaOptions !== 'allCurrentAndFuture') {

  //       const subAreaKeys = Object.keys(values.groupMappings.subArea[areaIdKey].checked);

  //       for (let j = 0; j < subAreaKeys.length; j++) {
  //         if (values.groupMappings.subArea[areaIdKey].checked[subAreaKeys[j]] === true) {
  //           const result = await api.addPermissionAreaSubArea(groupId, areaIdKey, subAreaKeys[j], undefined, undefined);
  //         }
  //       }

  //     } else if (subAreaData.subAreaOptions === 'allCurrentAndFuture') {
  //       const result = await api.addPermissionAreaSubArea(groupId, areaIdKey, undefined, true, undefined)
  //     }

  //   }

  // }

  const groupMappings = (groupMappingId: number) => {
    return (
      <Fragment>
        <div className='card__linking-container--left card-body border p-0'>
          <div className='p-3 pb-0'>
            <div className='has-tools'>
              <h5 className='mb-0'>Areas</h5>
              <div className='btn btn-outline-secondary btn-sm btn-sort-width'
              onClick={(e) => {
                sortListAreas(values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC
                  ? SORT_DIRECTION.DEC 
                  : SORT_DIRECTION.ASC
                );
              }}
              >
                {values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC
                  ? 'Ascending' 
                  : 'Descending'
                }
                <i className={values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC 
                  ? 'fa-solid fa-arrow-down-short-wide ms-1'
                  : 'fa-solid fa-arrow-up-wide-short ms-1'
                } />
              </div>
            </div>
            <input
              type='text'
              className='form-control form-control-sm my-3'
              placeholder='Search Areas'
              onChange={(e) => {
                handleChange(e, 'filterArea');
              }}
              value={values.filterArea}
            />
          </div>
          <hr className='my-4 mb-0' />
          <div className='form-check__container form-check__container--left' style={{ maxHeight: 'calc(100vh - 630px)' }}>
            {areas.map((area: any) => {
              if (values.filterArea && values.filterArea !== '' && area.Name.toLowerCase().indexOf(values.filterArea.toLowerCase()) === -1) {
                return <Fragment></Fragment>;
              }

              return (
                <div className='form-check'>
                  <div
                    className={(() => {
                      if (!values.groupMappings || !values.groupMappings.subArea || !values.groupMappings.subArea[area.Id]) return;
                      if (values.groupMappings.subArea[area.Id].subAreaOptions === 'allCurrentAndFuture') return 'form-check__active-indicator';

                      if (!values.groupMappings.subArea[area.Id].checked) return;
                      const subAreaKeys = Object.keys(values.groupMappings.subArea[area.Id].checked);

                      for (let i = 0; i < subAreaKeys.length; i++) {
                        if (values.groupMappings.subArea[area.Id].checked[subAreaKeys[i]]) {
                          return 'form-check__active-indicator';
                          break;
                        }
                      }
                    })()}>
                    <input
                      autoComplete='off'
                      type='radio'
                      id={'area' + area.Id}
                      name={'area'}
                      className='form-check-input'
                      value={area.Id}
                      checked={values.groupMappings && values.groupMappings?.area === area.Id}
                      onChange={(e) => {
                        handleChange(e, ['groupMappings', 'area']);
                      }}
                    />

                    <label className='form-check-label' htmlFor={'area' + area.Id}>
                      {area.Name}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center'>
          <i className='fa-solid fa-arrow-right' />
        </div>

        <div className='card__linking-container--right card-body border p-0'>
          <div className='p-3 pb-0'>
            <div className='has-tools'>
              <h5 className='mb-0'>Subareas</h5>
              <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                onClick={(e) => {
                  sortListSubArea(values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC
                    ? SORT_DIRECTION.DEC 
                    : SORT_DIRECTION.ASC
                  );
                }}
              >
                {values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC
                  ? 'Ascending' 
                  : 'Descending'
                }
                <i className={values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC 
                  ? 'fa-solid fa-arrow-down-short-wide ms-1'
                  : 'fa-solid fa-arrow-up-wide-short ms-1'
                } />
              </div>
            </div>
            <input
              type='text'
              className='form-control form-control-sm my-3'
              placeholder='Search Subareas'
              onChange={(e) => {
                handleChange(e, 'filterSubArea');
              }}
              value={values.filterSubArea}
            />
          </div>
          {areas.map((area: any, index: number) => {
            if (
              (values.groupMappings && values.groupMappings?.area && values.groupMappings.area !== area.Id) ||
              ((!values.groupMappings || !values.groupMappings.area) && index > 0)
            ) {
              return;
            }

            let allFutureOptions = ['groupMappings', 'subArea', `${area.Id}`, 'subAreaOptions'];
            let allFutureOptionsValue =
              values.groupMappings && values.groupMappings.subArea && values.groupMappings.subArea[`${area.Id}`]?.subAreaOptions;
            if (!values.groupMappings || values.groupMappings?.area !== area.Id) {
              allFutureOptions = ['groupMappings', 'subAreaGlobal', 'subAreaOptions'];
              allFutureOptionsValue = values.groupMappings && values.groupMappings.subAreaGlobal && values.groupMappings.subAreaGlobal.subAreaOptions;
            }

            const subAreaBoxes: any[] = [];

            subAreaBoxes.push(
              <Fragment>
                <div className='px-3'>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id={'allCurrentAndFuture' + area.Id}
                      name={'subAreaOptions' + area.Id}
                      className='form-check-input'
                      value={'allCurrentAndFuture'}
                      checked={allFutureOptionsValue === 'allCurrentAndFuture'}
                      onChange={(e) => {
                        handleChange(e, allFutureOptions);
                      }}
                    />
                    <label className='form-check-label' htmlFor={'allCurrentAndFuture' + area.Id}>
                      All current and future
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id={'allCurrent' + area.Id}
                      name={'subAreaOptions' + area.Id}
                      className='form-check-input'
                      value={'allCurrent'}
                      checked={allFutureOptionsValue === 'allCurrent'}
                      onChange={(e) => {
                        handleChange(e, allFutureOptions);
                      }}
                    />
                    <label className='form-check-label' htmlFor={'allCurrent' + area.Id}>
                      All current
                    </label>
                  </div>
                </div>
              </Fragment>
            );

            subAreaBoxes.push(<hr className='my-4 mb-0' />);

            if (!values.groupMappings || !values.groupMappings.area) return subAreaBoxes;
            if (!Array.isArray(area.Subareas)) return subAreaBoxes;

            const subAreaBoxesMain: any = [];
            area.Subareas.forEach((subArea: any) => {
              if (
                values.filterSubArea &&
                values.filterSubArea !== '' &&
                subArea.Name.toLowerCase().indexOf(values.filterSubArea.toLowerCase()) === -1
              ) {
                return <Fragment></Fragment>;
              }

              subAreaBoxesMain.push(
                <div className='form-check'>
                  <input
                    autoComplete='off'
                    type='checkbox'
                    className='form-check-input'
                    id={'subArea' + area.Id + subArea.Id}
                    checked={
                      values.groupMappings &&
                      values.groupMappings.subArea &&
                      values.groupMappings.subArea[`${area.Id}`] &&
                      values.groupMappings.subArea[`${area.Id}`].checked &&
                      values.groupMappings.subArea[`${area.Id}`].checked[subArea.Id]
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(
                        {
                          target: { value: e.target.checked },
                        },
                        ['groupMappings', 'subArea', `${area.Id}`, 'checked', subArea.Id]
                      );
                    }}
                  />
                  <label className='form-check-label' htmlFor={'subArea' + area.Id + subArea.Id}>
                    {subArea.Name}
                  </label>
                </div>
              );
            });
            subAreaBoxes.push(
              <div className='form-check__container form-check__container--right' style={{ maxHeight: 'calc(100vh - 691px)' }}>
                {subAreaBoxesMain}
              </div>
            );
            return subAreaBoxes;
          })}
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <div className='card steps-header' style={{ backgroundColor: '#F0F0F1' }}>
        <div className='steps-header__step active'>1. Areas</div>
        <span />
        <div className='steps-header__step'>2. Subreas</div>
        <span />
        <div className='steps-header__step'>3. Versions &amp; Milestones</div>
        <span />
        <div className='steps-header__step'>
          <i className='fas fa-check' />
        </div>
      </div>
      <div className='p-4 pb-0'>
        <label htmlFor='groupName' className='mb-2'>
          Security Group Name
        </label>
        <input
          type='text'
          className='form-control'
          id='groupName'
          name='groupName'
          placeholder='Security Group Name'
          onChange={(e) => handleChange(e, 'firstName')}
          value={values.firstName}
        />
      </div>

      <div className='card__linking-container p-4'>{groupMappings(0)}</div>

      {/* {(()=>{
          let count = 0;
          const display = [];
          do {
            display.push(groupMappings(count))
            count++;
          } while(count <= mappingCount);
          display.push(  <button onClick={() => {
            addAdditional();
          }}>Add additional area mapping</button>);
          return display;
        })()} */}
      <div className='d-flex align-items-center justify-content-between px-4 pb-5'>
        <button
          className='btn btn-outline-dark'
          onClick={() => {
            back(values);
          }}>
          <i className='fa-solid fa-arrow-left' />
          Back to Security Groups
        </button>
        <button
          className='btn btn-success'
          onClick={() => {
            next(values);
          }}>
          Next
          <i className='fa-solid fa-arrow-right' />
        </button>
      </div>
    </div>
  );
};

export default withRouter(GroupNewArea);
