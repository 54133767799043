import React from 'react'
import { withRouter } from "../routes/withRouter";

const AssignEntitiesUsers = (props: any) => {

  return (
      <div>
         AssignEntitiesUsers
      </div>
  );
}

export default withRouter(AssignEntitiesUsers);
