export enum SORT_DIRECTION {
    ASC,
    DEC,
}

export const sortList = (
    list: any[],
    properties: string[],
    sortDirection: SORT_DIRECTION
): any[] => {
    const newList = [...list];

    newList.sort((a: any, b: any) => {
        let fa = a;
        let fb = b;
        
        properties.forEach((property: string) => {
            fa = fa[property];
            fb = fb[property];
        });
        
        if (typeof fa === 'string') {
            fa = fa.toLowerCase();
        }

        if (typeof fb === 'string') {
            fb = fb.toLowerCase();
        }

        if (fa < fb) {
            return sortDirection === SORT_DIRECTION.ASC ? -1 : 1;
        }
        if (fa > fb) {
            return sortDirection === SORT_DIRECTION.ASC ? 1 : -1;
        }
        return 0;
    });

    return newList;
};
