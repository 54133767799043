import { NavLink } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

const HomeConnectorCard = ({ connectorType, to, skeleton = false }: { connectorType: any; to?: string; skeleton?: boolean }) => (
  <NavLink
    className={`card flex-row card-sm home-card ${skeleton ? 'card-skeleton' : 'card-interactive'}`}
    to={skeleton ? '' : to || PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', connectorType.Type.toLowerCase())}>
    <div className='card-body d-flex flex-row justify-content-start align-items-start' style={{ width: "100%" }}>
      {!skeleton && (
        <>
          <img
            className="me-auto"
            style={{ maxHeight: 50 }}
            src={`/img/b2c-connector-logo-${connectorType.Type.toLowerCase()}.svg`}
            alt={`${connectorType.Type.toLowerCase()} connector`}
          />
          {connectorType.IsEarlyStages && <div className='card-tag'>early stage</div>}
        </>
      )}
    </div>
  </NavLink>
);

export default HomeConnectorCard;
