import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from "../routes/withRouter";
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { PAGE_URLS } from '../routes/routes';
import { forEachTrailingCommentRange } from 'typescript';

const api = ApiService.getInstance();

const AssignUsersEntities = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [users, setUsers] = React.useState([]);
  const [entities, setEntities] = React.useState([]);
  const [values, setValues] = React.useState<any>({});

  const getUsers = async () => {
    const usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);
    if (usersFetched) {
      setUsers(usersFetched);
      // const storeAction = {
      //     type: REDUCER_ACTION_SET.SET_AREAS,
      //     payload: usersFetched,
      // };
      // dispatch(storeAction);
    }
  };

  const getEntities = async () => {
    const entitiesFetched = await api.getEntities();
    if (entitiesFetched) {
      setEntities(entitiesFetched);
      // const storeAction = {
      //     type: REDUCER_ACTION_SET.SET_SUB_AREAS,
      //     payload: subAreasFetched,
      // };
      // dispatch(storeAction);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getUsers();
      getEntities();
    })();
  }, []);

  const handleChange = (e: any, name: string, subName?: string) => {
    const newValues = { ...values };

    if (subName && !newValues[name]) {
      newValues[name] = {};
      newValues[name][subName] = e.target.value;
    } else if (subName && newValues[name]) {
      newValues[name][subName] = e.target.value;
    } else {
      newValues[name] = e.target.value;
    }

    setValues(newValues);
  };

  const linkAreaWithSubAreaList = async () => {
    if (values.applyFor === 'allCurrentAndFuture') {
      //const result = await api.linkAreaWithSubAreaList(values.area, undefined, true);
    } else {
      //
      const entitiesToLink: string[] = [];
      Object.keys(values.entity).forEach((entityId) => {
        if (values.entity[entityId]) {
          entitiesToLink.push(entityId);
        }
      });
      const result = await api.linkUserToEntity(values.user, entitiesToLink);
    }
    //getUsers();
  };

  return (
    <div>
      <div className='card card--height-limit'>
        <table className='table table--no-head-border'>
          <colgroup>
            <col style={{ width: '60%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr className='table-header'>
              <th>Name</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: any) => {
              let linkedEntities = '';
              user.B2SEntities &&
                Array.isArray(user.B2SEntities) &&
                user.B2SEntities.forEach((B2SEntity: any) => {
                  if (linkedEntities !== '') linkedEntities += ', ';
                  linkedEntities += B2SEntity.Name;
                });
              return (
                <tr>
                  <td>
                    {user.FirstName} {user.LastName} - {linkedEntities}
                  </td>
                  <td>
                    <div className='tools'>
                      <button
                        // onClick={() => {
                        //     handleChange(
                        //         {
                        //             target: {
                        //                 value: group,
                        //             },
                        //         },
                        //         'groupSelected'
                        //     );
                        // }}
                        className='btn btn-outline-primary btn-sm'>
                        <i className='fa-solid fa-pen-to-square' />
                        Edit
                      </button>
                      <button className='btn btn-outline-danger btn-sm'>
                        <i className='fa-solid fa-trash-can' />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <h3 className='page-heading has-tools mb-4'>
        Create new link
        <div className='tools'>
          <button className='btn btn-outline-secondary fw-bold'>
            <i className='fa-solid fa-arrow-left' />
            Back to links
          </button>
        </div>
      </h3>
      <div className='card card--height-limit no-overflow'>
        <div className='card__linking-container p-4'>
          <div className='card__linking-container--left card-body border p-0'>
            <div className='p-3 pb-0'>
              <div className='has-tools'>
                <h5 className='mb-0'>Areas</h5>
                <div className='btn btn-outline-secondary btn-sm'>
                  Ascending
                  <i className='fa-solid fa-arrow-down-short-wide ms-1' />
                </div>
              </div>
              <input type='text' className='form-control form-control-sm my-3' placeholder='Search Areas' />
            </div>
            <hr className='my-4 mb-0' />
            <div className='form-check__container form-check__container--left'>
              {users.map((user: any) => {
                return (
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id={'user' + user.Id}
                      name='user'
                      className='form-check-input'
                      value={user.Id}
                      checked={values.user === user.Id}
                      onChange={(e) => {
                        handleChange(e, 'user');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'user' + user.Id}>
                      {user.FirstName} {user.LastName}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center'>
            <i className='fa-solid fa-arrow-right' />
          </div>
          <div className='card__linking-container--right card-body border p-0'>
            <div className='p-3 pb-0'>
              <div className='has-tools'>
                <h5 className='mb-0'>Entities</h5>
                <div className='btn btn-outline-secondary btn-sm'>
                  Ascending
                  <i className='fa-solid fa-arrow-down-short-wide ms-1' />
                </div>
              </div>
              <input type='text' className='form-control form-control-sm my-3' placeholder='Search Entities' />

              <div>
                <div className='form-check'>
                  <input
                    autoComplete='off'
                    type='radio'
                    id={'allCurrent'}
                    name='allCurrent'
                    className='form-check-input'
                    value={'allCurrent'}
                    checked={values.applyFor === 'allCurrent'}
                    onChange={(e) => {
                      const newEntity: any = {};
                      entities.forEach((entity: any) => {
                        newEntity[entity.Id] = true;
                      });
                      setValues({
                        ...values,
                        entity: newEntity,
                        applyFor: 'allCurrent',
                      });
                      //handleChange(e, "applyFor");
                    }}
                  />
                  <label className='form-check-label' htmlFor={'allCurrent'}>
                    Select all current
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    autoComplete='off'
                    type='radio'
                    id={'allCurrentAndFuture'}
                    name='allCurrentAndFuture'
                    className='form-check-input'
                    value={'allCurrentAndFuture'}
                    checked={values.applyFor === 'allCurrentAndFuture'}
                    onChange={(e) => {
                      handleChange(e, 'applyFor');
                    }}
                  />
                  <label className='form-check-label' htmlFor={'allCurrentAndFuture'}>
                    Select all current and future
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    autoComplete='off'
                    type='radio'
                    id={'onlySelected'}
                    name='onlySelected'
                    className='form-check-input'
                    value={'onlySelected'}
                    checked={values.applyFor === 'onlySelected'}
                    onChange={(e) => {
                      handleChange(e, 'applyFor');
                    }}
                  />
                  <label className='form-check-label' htmlFor={'onlySelected'}>
                    Only selected
                  </label>
                </div>
              </div>
            </div>
            <hr className='my-4 mb-0' />
            <div className='form-check__container form-check__container--right'>
              {entities.map((entity: any) => {
                return (
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='checkbox'
                      className='form-check-input'
                      id={'entity' + entity.Id}
                      checked={values.entity ? values.entity[entity.Id] : false}
                      onChange={(e) => {
                        handleChange(
                          {
                            target: {
                              value: e.target.checked,
                            },
                          },
                          'entity',
                          entity.Id
                        );
                      }}
                    />
                    <label className='form-check-label' htmlFor={'entity' + entity.Id}>
                      {entity.Name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='text-end p-4 pt-0'>
          <button
            className='btn btn-success fw-bold d-inline'
            onClick={() => {
              linkAreaWithSubAreaList();
            }}>
            Create Link
            <i className='fa-solid fa-check ms-2' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AssignUsersEntities);
