import { createRef, useEffect, useReducer, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

import ReCAPTCHA from 'react-google-recaptcha';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';

const SignupFinalise = () => {
  const reducer = (state: any, value: any) => ({
    ...state,
    [value.id]: value.value,
  });

  const recaptchaRef = createRef<any>();
  const formRef = createRef<any>();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [linkAccount, setLinkAccount] = useState(false);
  const [linkOrganisation, setLinkOrganisation] = useState(false);
  const [approvalSubmitted, setApprovalSubmitted] = useState(false);
  const [awaitingApproval, setAwaitingApproval] = useState(false);
  const [code, setCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [provider, setProvider] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [model, setModel] = useReducer(reducer, {
    password: '',
    organisationName: '',
  });

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);
  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    const code = searchParams.get('code');

    if (!!code) {
      setCode(code);
      setShowLoader(true);

      ApiService.getInstance()
        .signupFinalise('GET', { code })
        .then((response) => {
          if (response.data.Finalised) {
            document.cookie = `logged_in_name=${response.data.FirstName}`;
            navigate(`${PAGE_URLS.SIGNIN}?registration=success&provider=${response.data.Provider}`);
          }

          setAwaitingApproval(!!response.data.ApprovalId);
          setLinkOrganisation(response.data.LinkOrganisation);
          setLinkAccount(response.data.LinkAccount);
          setEmailAddress(response.data.EmailAddress);
          setFirstName(response.data.FirstName);
          setProvider(response.data.Provider);
          setShowContent(true);
          setShowLoader(false);
        });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  const signup = async () => {
    setShowLoader(true);

    recaptchaRef?.current?.reset();

    const token = await recaptchaRef?.current?.executeAsync();
    const result = await ApiService.getInstance().signupFinalise('POST', {
      organisation: {
        companyName: model.organisationName,
      },
      account: {
        password: model.password,
      },
      code: code,
      recaptchaToken: token,
    });

    if (result.error) {
      setError(result.message);
      setShowLoader(false);
    }

    if (result.status === 201 || result.status === 200) {
      if (linkOrganisation) {
        setApprovalSubmitted(true);
      } else {
        document.cookie = `logged_in_name=${firstName}`;
        navigate(`${PAGE_URLS.SIGNIN}?registration=success`);
      }
    } else {
      setError(result?.message || 'Something seems to have gone wrong.');
      setShowLoader(false);
    }
  };

  const onFormInputChange = (event: any) => setModel(event.target);

  if (!showContent) {
    return (
      <div className='card-loader'>
        <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
          <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
        </svg>
      </div>
    );
  }

  if (approvalSubmitted) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-7 col-lg-6 col-xl-5'>
            <div className='m-4 mt-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-2 text-nowrap light-text'>
                  Finish signing up to <b>Business2Cloud</b>
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body'>
                <div className='form'>
                  <FormSection>
                    <span>Your sign up request has been submitted for approval.</span>
                    <span className='light-text'>
                      If you don't receive an email stating that your sign up request was approved within the next 24 hours, please check your spam
                      folder and adjust your filtering to allow emails from <i>larasoft.global</i>, or try signing up again.
                    </span>
                    <a href={PAGE_URLS.SIGNIN} className='btn btn-sm btn-primary me-auto mt-3'>
                      <span className='material-icons-two-tone'>arrow_back_ios</span>
                      Return to sign in
                    </a>
                  </FormSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (awaitingApproval) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-5'>
            <div className='m-4 mt-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-2 text-nowrap light-text'>
                  Finish signing up to <b>Business2Cloud</b>
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body'>
                <div className='form'>
                  <FormSection>
                    <span>Your sign up request is awaiting approval.</span>
                    <span className='light-text'>
                      If you don't receive an email stating that your sign up request was approved within the next 24 hours, please check your spam
                      folder and adjust your filtering to allow emails from <i>larasoft.global</i>, or try signing up again.
                    </span>
                    <a href={PAGE_URLS.SIGNIN} className='btn btn-sm btn-primary me-auto mt-3'>
                      <span className='material-icons-two-tone'>arrow_back_ios</span>
                      Return to sign in
                    </a>
                  </FormSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (linkOrganisation) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className={`col-12 col-md-6 ${linkAccount ? 'col-lg-4' : ''}`}>
            <div className='m-4 mt-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-2 text-nowrap light-text'>
                  Finish signing up to <b>Business2Cloud</b>
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body' ref={formRef}>
                <div className='form'>
                  {error && (
                    <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                      <span>
                        <div dangerouslySetInnerHTML={{ __html: error }}></div>
                        <i className='material-icons-two-tone' onClick={onErrorHideClick}>
                          cancel
                        </i>
                      </span>
                    </div>
                  )}
                  <div>
                    <FormSection>
                      <h5>Join exising organisation</h5>
                      <span>
                        You are trying to authorise <b className='text-capitalize'>{provider || 'external provider'}</b> entities that already exist
                        for another organisation. Would you like to request permission to join that organisation?
                      </span>
                      <span className='light-text'>
                        Should you choose not to join the existing organisation, you will not be able to access nor authorise access to these{' '}
                        <b className='text-capitalize'>{provider || 'external provider'}</b> entities from within <b>Business2Cloud</b>.
                      </span>
                      <div className='d-flex flex-row gap-2 mt-3'>
                        <button className='btn btn-sm btn-primary ms-auto px-4' onClick={signup}>
                          Yes
                        </button>
                        <button className='btn btn-sm btn-outline-secondary px-4'>No</button>
                      </div>
                    </FormSection>
                  </div>
                  <ReCAPTCHA
                    size='invisible'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onExpired={() => {
                      recaptchaRef?.current?.reset();
                    }}
                  />
                </div>
              </div>
              {showLoader && (
                <div className='card-loader'>
                  <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                    <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-container container'>
      <div className='row justify-content-center'>
        <div className={`col-12 col-md-6 ${linkAccount ? 'col-lg-4' : ''}`}>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-2 text-nowrap light-text'>
                Finish signing up to <b>Business2Cloud</b>
              </span>
            </div>
          </div>
          <div className='card card-sm mb-5'>
            <div className='card-body' ref={formRef}>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-icons-two-tone' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={signup}>
                    <FormSection>
                      <div className='form-section-header'>
                        {linkAccount && (
                          <>
                            <h5>Sign in to continue</h5>
                            <span>
                              An account already exists using your email address. Please sign to link it with your{' '}
                              <b className='text-capitalize'>{provider}</b> credentials.
                            </span>
                          </>
                        )}
                        {!linkAccount && (
                          <>
                            <h5>Continue</h5>
                            <span className='text-nowrap light-text'>
                              We're nearly done, <b>{firstName}</b>. We just need to know a little more about your organisation.
                            </span>
                          </>
                        )}
                      </div>
                      {linkAccount && (
                        <>
                          <FormInput id='emailAddress' placeholder='Email address' required={true} value={emailAddress} disabled={true} />
                          <FormInput
                            id='password'
                            placeholder='Password'
                            required={true}
                            type='password'
                            value={model.password}
                            onChange={onFormInputChange}
                          />
                          <small className='mb-1 d-inline-block'>
                            <Link to='/reset-password'>Forgot password?</Link>
                          </small>
                        </>
                      )}
                      {!linkAccount && (
                        <FormInput
                          id='organisationName'
                          placeholder='Organisation name'
                          required={true}
                          value={model.organisationName}
                          onChange={onFormInputChange}
                        />
                      )}
                      <div className='d-flex'>
                        <button className='btn btn-secondary me-auto  mt-3' type='submit'>
                          Finish signing up
                        </button>
                      </div>
                    </FormSection>
                  </Form>
                  <ReCAPTCHA
                    size='invisible'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onExpired={() => {
                      recaptchaRef?.current?.reset(); // here
                    }}
                  />
                </div>
              </div>
            </div>
            {showLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignupFinalise);
