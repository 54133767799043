import { ApiService } from '../services/apiService';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { useState } from 'react';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const Approvals = ({ approval }: { approval: any }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [_approval, setApproval] = useState(approval);

  const updateApproval = async (approvalStatus: string) => {
    setShowLoader(true);
    await ApiService.getInstance().updateApproval(approval.Id, approvalStatus);
    setApproval({
      ..._approval,
      ApprovalStatus: approvalStatus,
      ApprovedAtUtc: new Date(),
    });
    setShowLoader(false);
  };

  const renderStatusIcon = () => {
    switch (_approval.ApprovalStatus) {
      case 'PENDING':
        return (
          <span className={`material-icons-two-tone user-select-none pending`} title='Pending'>
            pending
          </span>
        );
      case 'APPROVED':
        return (
          <span className={`material-icons-two-tone user-select-none active`} title='Approved'>
            check_circle
          </span>
        );
      case 'REJECTED':
        return (
          <span className={`material-icons-two-tone user-select-none alert`} title='Rejected'>
            cancel
          </span>
        );
      default:
        return (
          <span className={`material-icons-two-tone user-select-none pending`} title='Unkown'>
            help
          </span>
        );
    }
  };

  const renderTitle = () => _approval.ApprovalType.replaceAll('_', ' ').toLowerCase();

  const getContext = () => JSON.parse(_approval.ApprovalContext);

  return (
    <div className='card card-sm grid-item' style={{ height: 200 }}>
      <div className='card-header align-items-start'>
        <div>
          <div className='text-capitalize'>{renderTitle()}</div>
          {_approval.ApprovalStatus !== 'PENDING' && (
            <small className='light-text'>
              <span className='text-capitalize'>{_approval.ApprovalStatus.toLowerCase()}</span> <b>{dayjs(_approval.ApprovedAtUtc).fromNow()}</b> by{' '}
              <b>{_approval.ApprovedUser}</b>
            </small>
          )}
        </div>
        <span className='ms-auto'>{renderStatusIcon()}</span>
      </div>
      <div className='card-body'>{getContext().Description}</div>
      <div className='card-footer'>
        {_approval.ApprovalStatus === 'PENDING' && (
          <div className='d-flex flex-row gap-2 justify-content-end'>
            <button className='btn btn-sm btn-primary' onClick={() => updateApproval('APPROVED')}>
              Approve
            </button>
            <button className='btn btn-sm btn-outline-danger' onClick={() => updateApproval('REJECTED')}>
              Reject
            </button>
          </div>
        )}
      </div>
      {showLoader && (
        <div className='card-loader'>
          <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
            <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Approvals;
