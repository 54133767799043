import { SqlCreds } from '../interfaces/xero2sqltoken';
import Business2CloudApiService from './Business2CloudApiService';

export class ApiService {
  private static _instance: ApiService = new ApiService();

  private _jwt: string | undefined;

  constructor() {
    if (ApiService._instance) {
      throw new Error('Error: Instantiation failed: Use ApiService.getInstance() instead of new.');
    }
    ApiService._instance = this;

    // get cached browser data
    let cachedData: any = localStorage.getItem('cachedData');
    let jwt = undefined;
    try {
      cachedData = cachedData && cachedData !== '' ? JSON.parse(cachedData) : undefined;
      jwt = cachedData && cachedData.jwt ? cachedData.jwt : undefined;
    } catch (error) {
      jwt = undefined;
    }

    this._jwt = jwt;
  }

  public static logout(): void {
    localStorage.setItem('cachedData', '');
    localStorage.setItem('cachedDataStore', '');
    localStorage.setItem('isAuthenticated', 'false');
    window.location.replace('/');
  }

  public static getInstance(): ApiService {
    return ApiService._instance;
  }

  public setJwt(value: string): void {
    this._jwt = value;

    Business2CloudApiService.Instance.setJwt(value);
  }

  public getJwt(): string | undefined {
    return this._jwt;
  }

  public verify = async (reference: any, code: string, context: any): Promise<any> =>
    portalFetch('POST', '/verify', {
      reference,
      code,
      context,
    });

  public setMfa = async (enable: boolean, verificationReference: string | undefined, verificationCode: string | undefined) =>
    portalFetch('POST', '/me/security/mfa', { enable, verificationReference, verificationCode }, this._jwt);

  public deleteMfaDevice = async (reference: string) => portalFetch('DELETE', `/me/security/mfa/devices/${reference}`, null, this._jwt);

  public getApprovals = async (): Promise<any> => portalFetch('GET', '/approvals', null, this._jwt);
  public updateApproval = async (approvalId: string, approvalStatus: string) =>
    portalFetch('POST', '/approvals', { approvalId, approvalStatus }, this._jwt);

  public signup = async (registration: any): Promise<any> => portalFetch('POST', '/registration/account', registration);
  public signupFinalise = async (method: string, registration: any): Promise<any> =>
    method === 'GET'
      ? portalFetch('GET', `/registration/finalise?code=${encodeURIComponent(registration.code)}`, null)
      : portalFetch('POST', `/registration/finalise`, {
          ...registration,
          code: encodeURIComponent(registration.code),
        });

  public resetPassword = async (resetPassword: any): Promise<any> => portalFetch('POST', '/reset-password', resetPassword);
  public resetPasswordRequest = async (resetPassword: any): Promise<any> => portalFetch('POST', '/reset-password/request', resetPassword);
  public validateResetPasswordRedeemToken = async (token: string): Promise<any> =>
    portalFetch('GET', `/reset-password/validate?token=${token}`, null);

  public async loginWithUsernamePassword(user: string, password: string): Promise<any> {
    return await portalFetch('POST', '/auth/signin', {
      emailAddress: user,
      password,
    });
  }
  public async loginWithVerificationCode(
    verificationReference: string | undefined,
    verificationCode: string | undefined,
    verificationTrustDevice: boolean
  ): Promise<any> {
    return await portalFetch('POST', '/auth/signin', {
      verificationReference,
      verificationCode,
      verificationTrustDevice,
    });
  }
  public async loginWithCode(code: string): Promise<any> {
    return await portalFetch('POST', '/auth/signin', {
      code: encodeURIComponent(code),
    });
  }

  public verifyEmailAddress = (emailAddress: string): Promise<{ data: boolean; status: number; message?: string }> =>
    portalFetch('GET', `/registration/verify?emailAddress=${emailAddress}`, null);

  public async getOrganisations(): Promise<any> {
    const result = await portalFetch('GET', '/organisations', null, this._jwt);
    return result;
  }

  public async getEntities(): Promise<any> {
    const result = await portalFetch('GET', '/entities', null, this._jwt);
    return result;
  }

  public async getAreas(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/areas${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);

    return result;
    //return await result.json();
  }

  public async createArea(name: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', '/areas', { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async updateArea(name: string, organisationId: string, areaId: string, statusId: string): Promise<any> {
    const result = await portalFetch('POST', `/areas/${areaId}`, { name, comOrganisationId: organisationId, statusId }, this._jwt);
    return result;
  }

  public async deleteArea(name: string, organisationId: string, areaId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/areas/${areaId}`, { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async getSubAreas(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/subareas${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);
    return result;
  }

  public async createSubArea(name: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', '/subareas', { name, comOrganisationId: organisationId, listOrder: 1 }, this._jwt);
    return result;
  }

  public async updateSubArea(name: string, organisationId: string, subAreaId: string, statusId: string): Promise<any> {
    const result = await portalFetch(
      'POST',
      `/subareas/${subAreaId}`,
      { name, comOrganisationId: organisationId, listOrder: 1, statusId },
      this._jwt
    );
    return result;
  }

  public async deleteSubArea(name: string, organisationId: string, subAreaId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/subareas/${subAreaId}`, { name, comOrganisationId: organisationId, listOrder: 1 }, this._jwt);
    return result;
  }

  public async getVersions(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/versions${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);
    return result;
  }

  public async createVersion(name: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', '/versions', { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async updateVersion(name: string, organisationId: string, versionId: string, statusId: string): Promise<any> {
    const result = await portalFetch('POST', `/versions/${versionId}`, { name, comOrganisationId: organisationId, statusId }, this._jwt);
    return result;
  }

  public async deleteVersion(name: string, organisationId: string, versionId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/versions/${versionId}`, { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async getMilestones(versionId: string): Promise<any> {
    const result = await portalFetch('GET', '/milestones/' + versionId, null, this._jwt);
    return result;
  }

  public async createMilestone(name: string, versionId: string): Promise<any> {
    const result = await portalFetch('POST', '/milestones', { name, versionId, listOrder: 1 }, this._jwt);
    return result;
  }

  public async updateMilestone(name: string, organisationId: string, milestoneId: string, statusId: string): Promise<any> {
    const result = await portalFetch(
      'POST',
      `/milestones/${milestoneId}`,
      {
        name,
        comOrganisationId: organisationId,
        listOrder: 1,
        versionId: 1,
        statusId,
      },
      this._jwt
    );
    return result;
  }

  public async deleteMilestone(milestoneId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/milestones/${milestoneId}`, {}, this._jwt);
    return result;
  }

  public async addPermissionVersionMilestone(
    groupId: string,
    versionId?: string,
    milestoneId?: string,
    versionAllCurrentAndFuture?: boolean,
    milestoneAllCurrentAndFuture?: boolean
  ): Promise<any> {
    const result = await portalFetch(
      'POST',
      '/permission-groups/version-milestone',
      {
        groupId,
        versionId,
        milestoneId,
        versionAllCurrentAndFuture,
        milestoneAllCurrentAndFuture,
      },
      this._jwt
    );
    //return result;
  }

  public async deletePermissionVersionMilestone(groupId: string): Promise<any> {
    const result = await portalFetch('DELETE', '/permission-groups/version-milestone', { groupId }, this._jwt);
  }

  public async getHelperTables(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/helperTables${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);
    return result;
  }

  public async addHelperTable(name: string, inputTableName: string, organisationId: string): Promise<any> {
    const result = await portalFetch(
      'POST',
      '/helperTables',
      {
        name,
        inputTableName,
        budget2SqlProductId: organisationId, //TODO rename budget2SqlProductId in backend API to organisationId
      },
      this._jwt
    );
    return result;
  }

  public async editHelperTable(name: string, inputTableName: string, organisationId: string, helperTableId: string, statusId: string): Promise<any> {
    const result = await portalFetch(
      'POST',
      `/helperTables/${helperTableId}`,
      {
        name,
        inputTableName,
        budget2SqlProductId: organisationId, //TODO rename budget2SqlProductId in backend API to organisationId
        statusId,
      },
      this._jwt
    );
    return result;
  }

  public async deleteHelperTable(helperTableId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/helperTables/${helperTableId}`, {}, this._jwt);
    return result;
  }

  public async linkAreaWithSubAreaList(areaId: string, subAreas?: string[], allCurrentAndFuture?: boolean): Promise<any> {
    if (allCurrentAndFuture) {
      const result = await portalFetch('POST', '/subareas/link-to-area', { areaId, subareaId: null, allCurrentAndFuture }, this._jwt);
      return true;
    }
    if (subAreas && Array.isArray(subAreas)) {
      for (let i = 0; i < subAreas.length; i++) {
        const subareaId = subAreas[i];
        const result = await portalFetch('POST', '/subareas/link-to-area', { areaId, subareaId }, this._jwt);
      }
    }
    // TODO add error handling
    return true;
  }

  public async unlinkAreaWithSubAreaList(areaId: string, subAreas?: string[], allCurrentAndFuture?: boolean): Promise<any> {
    if (allCurrentAndFuture) {
      const result = await portalFetch('POST', '/subareas/unlink-from-area', { areaId, allCurrentAndFuture }, this._jwt);
    } else if (Array.isArray(subAreas)) {
      for (let i = 0; i < subAreas.length; i++) {
        const subareaId = subAreas[i];
        const result = await portalFetch('POST', '/subareas/unlink-from-area', { areaId, subareaId }, this._jwt);
      }
    }
    // TODO add error handling
    return true;
  }

  public async unlinkAreaWithSubAreaListAll(areaId: string): Promise<any> {
    const result = await portalFetch('POST', '/subareas/unlink-from-area-all', { areaId }, this._jwt);
  }

  public async getUsers(organisationId?: string): Promise<any> {
    const result = await portalFetch('GET', `/users${organisationId ? '?orgId=' + organisationId : ''}`, null, this._jwt);
    return result;
  }

  public async createUser(
    firstName: string,
    lastName: string,
    emailAddress: string,
    password: string,
    roleId: string,
    organisationId: string
  ): Promise<any> {
    return await portalFetch('POST', '/users', { firstName, lastName, emailAddress, password, roleId, organisationId }, this._jwt);
  }

  public async updateUser(
    userId: string,
    firstName: string,
    lastName: string,
    emailAddress: string,
    password: string,
    roleId: string,
    statusId: string,
    msId?: string
  ): Promise<any> {
    const result = await portalFetch('POST', `/users/${userId}`, { firstName, lastName, emailAddress, password, roleId, statusId, msId }, this._jwt);
    return result;
  }

  public async deleteUser(userId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/users/${userId}`, {}, this._jwt);
    return result;
  }

  public async getRoles(): Promise<any> {
    const result = await portalFetch('GET', '/roles', null, this._jwt);
    return result;
  }

  public async getGroups(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/permission-groups${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);
    return result;
  }

  public async setGroupStatus(organisationId: string, groupId: string, statusId: string): Promise<any> {
    const result = await portalFetch('POST', '/permission-groups/status', { comOrganisationId: organisationId, groupId, statusId }, this._jwt);
    return result;
  }

  public async getXero2SqlTokens(): Promise<any> {
    const result = await portalFetch('GET', '/token', null, this._jwt);
    return result;
  }

  public async getXero2SqlToken(id: string): Promise<any> {
    const result = await portalFetch('GET', `/token/${id}`, null, this._jwt);
    return result;
  }

  public async setXero2SqlTokenCreds(tokenId: string, creds: SqlCreds): Promise<any> {
    const result = await portalFetch('PATCH', `/token/${tokenId}`, creds, this._jwt);
    return result;
  }

  public async getXero2SqlProducts(): Promise<any> {
    const result = await portalFetch('GET', '/me/xero2sqlproducts', null, this._jwt);
    return result;
  }

  public async getXero2SqlProductsAll(orgId?: string): Promise<any> {
    const result = await portalFetch('GET', `/users/xero2sqlproduct${orgId ? '?orgId=' + orgId : ''}`, null, this._jwt);
    return result;
  }

  public async deleteLinkUserToXero2SqlProductsAll(userId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/users/${userId}/xero2sqlproduct`, {}, this._jwt);
    //return result;
  }

  public async linkUserToXero2SqlProduct(userId: string, poroductId: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', `/users/${userId}/link-xero2sqlproduct`, { xero2SqlProductId: poroductId }, this._jwt);
    //return result;
  }

  public async startXero2SqlIncrementalSync(id: string): Promise<any> {
    const result = await portalFetch('POST', `/xero2sqlproducts/${id}/incremental`, {}, this._jwt);
    return result;
  }

  public async addGroup(name: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', '/permission-groups', { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async updateGroup(name: string, organisationId: string, groupId: string): Promise<any> {
    const result = await portalFetch('POST', '/permission-groups', { name, comOrganisationId: organisationId, groupId }, this._jwt);
    return result;
  }

  public async deleteGroup(groupId: string): Promise<any> {
    const result = await portalFetch('DELETE', '/permission-groups/groups', { groupId }, this._jwt);
    return result;
  }

  public async addPermissionAreaSubArea(
    groupId: string,
    areaId?: string,
    subAreaId?: string,
    areaAllCurrentAndFuture?: boolean,
    subAreaAllCurrentAndFuture?: boolean
  ): Promise<any> {
    const result = await portalFetch(
      'POST',
      '/permission-groups/area-subarea',
      {
        groupId,
        areaId,
        subAreaId,
        areaAllCurrentAndFuture,
        subAreaAllCurrentAndFuture,
      },
      this._jwt
    );
    //return result;
  }

  public async deletePermissionAreaSubArea(groupId: string): Promise<any> {
    const result = await portalFetch('DELETE', '/permission-groups/area-subarea', { groupId }, this._jwt);
  }

  public async linkUserToGroup(userId: string, groupId: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', `/users/${userId}/link-to-group`, { comOrganisationId: organisationId, groupId }, this._jwt);
    //return result;
  }

  public async deleteLinkUserToGroup(userId: string, orgId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/users/${userId}/link-to-group?orgId=${orgId}`, {}, this._jwt);
    //return result;
  }

  public async linkUserToEntity(userId: string, entities?: string[], allCurrentAndFuture?: boolean): Promise<any> {
    // TODO
    // if (allCurrentAndFuture) {
    //     const result = await portalFetch(
    //         'POST',
    //         `${userId}/link-to-entity`,
    //         { allCurrentAndFuture },
    //         this._jwt
    //     );
    //     return true;
    // }
    if (entities && Array.isArray(entities)) {
      for (let i = 0; i < entities.length; i++) {
        const entityId = entities[i];
        const result = await portalFetch('POST', `/users/${userId}/link-to-entity`, { entityId }, this._jwt);
      }
    }
    // TODO add error handling
    return true;
  }

  public async linkUserToHelperTable(userId: string, helperTables?: string[], allCurrentAndFuture?: boolean): Promise<any> {
    // TODO
    // if (allCurrentAndFuture) {
    //     const result = await portalFetch(
    //         'POST',
    //         `${userId}/link-to-entity`,
    //         { allCurrentAndFuture },
    //         this._jwt
    //     );
    //     return true;
    // }
    if (helperTables && Array.isArray(helperTables)) {
      for (let i = 0; i < helperTables.length; i++) {
        const helperTableId = helperTables[i];
        const result = await portalFetch('POST', `/users/${userId}/link-helperTable`, { helperTableId }, this._jwt);
      }
    }
    // TODO add error handling
    return true;
  }

  public async deleteLinkUserToHelperTable(userId: string, orgId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/users/${userId}/link-helperTable?orgId=${orgId}`, {}, this._jwt);
    // TODO add error handling
    return true;
  }

  public async createEntity(name: string, organisationId: string): Promise<any> {
    const result = await portalFetch('POST', '/entities', { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async updateEntity(name: string, organisationId: string, entityId: string): Promise<any> {
    const result = await portalFetch('POST', `/entities/${entityId}`, { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async deleteEntity(name: string, organisationId: string, entityId: string): Promise<any> {
    const result = await portalFetch('DELETE', `/entities/${entityId}`, { name, comOrganisationId: organisationId }, this._jwt);
    return result;
  }

  public async signinB2S(): Promise<any> {
    const result = await portalFetch('POST', `/auth/signin-b2s`, {}, this._jwt);
    return result;
  }

  public async tableSync(credsId: string): Promise<any> {
    const result = await portalFetch('GET', `/token/${credsId}/sync-tables`, {}, this._jwt);
    return result;
  }

  public async setDatabaseCredentials(
    organisationId: string,
    host: string,
    database: string,
    schema: string,
    password: string | null,
    user: string
  ): Promise<any> {
    const result = await portalFetch(
      'POST',
      `/organisations/${organisationId}/database-credentials`,
      { host, database, schema, password, user },
      this._jwt
    );
    return result;
  }

  public async getDatabaseCredentials(organisationId: string): Promise<any> {
    const result = await portalFetch('GET', `/organisations/${organisationId}/database-credentials`, {}, this._jwt);
    return result;
  }

  public async tableSyncByOrganisationId(organisationId: string): Promise<any> {
    const result = await portalFetch('GET', `/organisations/${organisationId}/database-table-sync`, {}, this._jwt);
    return result;
  }

  public async getXeroDatabaseCredentials(productId: string): Promise<any> {
    const result = await portalFetch('GET', `/xero2sqlproducts/${productId}/database-credentials`, {}, this._jwt);
    return result;
  }

  public async setXeroDatabaseCredentials(productId: string, creds: SqlCreds): Promise<any> {
    const result = await portalFetch('POST', `/xero2sqlproducts/${productId}/database-credentials`, creds, this._jwt);
    return result;
  }

  public async getStatuses(): Promise<any> {
    const result = await portalFetch('GET', `/statuses`, null, this._jwt);
    return result;
  }
}

const portalFetch = async (method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD', endpoint: string, body: any, jwt?: string) => {
  const options: any = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: method === 'GET' || method === 'DELETE' || method === 'HEAD' ? undefined : JSON.stringify(body),
    credentials: 'include',
  };

  if (jwt) {
    options.headers['Authorization'] = 'Bearer ' + jwt;
  }

  let result: any = await fetch(process.env.REACT_APP_PORTAL_API_URL + endpoint, options);

  const responseBody = await result.text();

  if (!!responseBody) {
    result = JSON.parse(responseBody);
  }

  if (
    result &&
    (result.statusCode === 401 || result.message === 'Unauthorized') &&
    endpoint.indexOf('verify') === -1 &&
    endpoint.indexOf('signin') === -1 &&
    endpoint.indexOf('signup') === -1 &&
    endpoint.indexOf('registration') === -1
  ) {
    return ApiService.logout();
  }

  return result;
};
