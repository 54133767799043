import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiService } from '../services/apiService';
import ApprovalCard from './ApprovalCard';

const Approvals = () => {
  const { approvalId } = useParams();

  const [searchParams] = useSearchParams();
  const [approvalsList, setApprovalsList] = useState([]);

  useEffect(() => {
    const approvalStatus = searchParams.get('status');

    if (approvalId && approvalStatus) {
      ApiService.getInstance().updateApproval(approvalId, approvalStatus);
    }

    ApiService.getInstance()
      .getApprovals()
      .then((response) => {
        setApprovalsList(response);
      });
  }, []);

  return (
    <div className='grid'>
      {approvalsList.map((approval: any) => (
        <ApprovalCard key={approval.Id} approval={approval} />
      ))}
    </div>
  );
};

export default Approvals;
