/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, KeyboardEvent, createRef, useContext, useEffect, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { PAGE_URLS } from '../routes/routes';
import { ApiService } from '../services/apiService';
import { Store } from '../store/Store';
import { REDUCER_ACTION_SET } from '../store/types';
import { Link, useSearchParams } from 'react-router-dom';

import FormSection from './FormSection';
import Form from './Form';
import FormInput from './FormInput';

const api = ApiService.getInstance();

const Signin = (props: any) => {
  const store = useContext(Store);
  const formRef = createRef<any>();

  const [searchParams] = useSearchParams();
  const [registrationMessage, setRegistrationMessage] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [loggedInName, setLoggedInName] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  useEffect(() => {
    const provider = searchParams.get('provider');
    const error = searchParams.get('error');

    if (!!error) {
      if (error === 'unauthorised')
        setError(
          `Your <b>${provider || 'external provider'}</b> credentials have not been linked to a user.<br />Please <b>sign up with ${
            provider || 'the external provider'
          }</b> below and try again.`
        );
      if (error === 'unknown')
        setError(
          `Something seems to have gone wrong while trying to sign in using <b>${
            provider || 'the external provider'
          }</b>.<br /><br />Please try again.`
        );
    }

    const code = searchParams.get('code');

    if (!!code) {
      login(code);
      return;
    }

    const registration = searchParams.get('registration');

    setRegistrationMessage(registration === 'success');

    let loggedInName =
      searchParams.get('name') ||
      document.cookie
        .split('; ')
        .find((_) => _.startsWith('logged_in_name='))
        ?.split('=')[1];

    if (loggedInName) setLoggedInName(loggedInName);

    setShowLoader(false);
  }, [searchParams]);

  const onUsernameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setUsername(event.currentTarget.value);
  };

  const onPasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setPassword(event.currentTarget.value);
  };

  const onInputKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && username.length && password.length) {
      await login();
    }
  };

  const login = async (code?: string) => {
    setShowLoginLoader(true);

    try {
      setError('');

      const resJson: any = !!code ? await api.loginWithCode(code) : await api.loginWithUsernamePassword(username, password);

      if (resJson.verificationRequired) {
        props.router.navigate(`${PAGE_URLS.SIGNIN_CONFIRM}?reference=${resJson.verificationReference}`);
      }

      if (resJson.statusCode && resJson.statusCode === 404) {
        throw 'not found';
      }

      if (!resJson?.accessToken || (resJson.statusCode && resJson.statusCode === 401)) {
        setError('Please check your email address and password.');
        setShowLoginLoader(false);
        return;
      }

      api.setJwt(resJson.accessToken);

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_JWT_TOKEN,
        payload: resJson.accessToken,
      });

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_USER,
        payload: {
          firstName: resJson.firstName,
          lastName: resJson.lastName,
          role: resJson.role,
          roleLevel: resJson.roleLevel,
          email: resJson.email,
          requireMultiFactorAuthentication: resJson.requireMultiFactorAuthentication,
          verificationDevices: resJson.verificationDevices,
        },
      });

      document.cookie = 'logged_in=true';
      document.cookie = `logged_in_name=${resJson.firstName}`;

      const returnUrl = searchParams.get('returnUrl');

      if (!!returnUrl) {
        props.router.navigate(decodeURIComponent(returnUrl));
      } else {
        props.router.navigate(PAGE_URLS.HOME);
      }
    } catch (err) {
      setError('Something seems to have gone wrong, please try again.');
    }

    setShowLoginLoader(false);
  };

  if (showLoader) return <div></div>;

  return (
    <div className={`login-container container pb-5 ${process.env.REACT_APP_SIGN_IN_WITH_XERO !== 'true' && 'pt-5'}`}>
      <div className='row justify-content-center'>
        <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-4 text-nowrap light-text'>
                {loggedInName ? (
                  registrationMessage ? (
                    <div>
                      Welcome, <b>{loggedInName}</b>
                      <div className='mt-3 small-text d-block'>Please watch out for a welcome email which will provide you with the next steps.</div>
                    </div>
                  ) : (
                    <div>
                      Welcome back, <b>{loggedInName}</b>
                    </div>
                  )
                ) : (
                  <div>
                    Sign in to <b>Business2Cloud</b>
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className='card card-sm'>
            <div className='card-body' ref={formRef}>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-icons-two-tone' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={() => login()}>
                    <FormSection>
                      <FormInput
                        id='username'
                        placeholder='Username'
                        required={true}
                        value={username}
                        onChange={onUsernameInputChange}
                        onKeyUp={onInputKeyUp}
                      />
                      <FormInput
                        type='password'
                        id='password'
                        placeholder='Password'
                        required={true}
                        value={password}
                        onChange={onPasswordInputChange}
                        onKeyUp={onInputKeyUp}
                      />
                      <small className='mb-1 m-auto'>
                        <Link to='/reset-password'>Forgot password?</Link>
                      </small>
                      <button
                        className='btn btn-sm btn-secondary justify-content-center flex-grow-1'
                        type='button'
                        disabled={!username.length || !password.length}
                        onClick={() => login()}>
                        Sign in
                      </button>
                    </FormSection>
                    {process.env.REACT_APP_SIGN_IN_WITH_XERO == 'true' && (
                      <FormSection divider={true} dividerLabel='or'>
                        <iframe
                          title='Sign in with Xero'
                          src={`/signin-with-xero.html?api=${process.env.REACT_APP_B2C_API_URL}`}
                          style={{ height: 52, margin: -8 }}
                          sandbox='allow-scripts allow-top-navigation'></iframe>
                      </FormSection>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {showLoginLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
          <div className='d-flex flex-column mt-4 gap-2'>
            <span className='d-flex justify-content-center'>
              No account?&nbsp;<Link to='/signup'>Sign up</Link>&nbsp;now.
            </span>
            {process.env.REACT_APP_SIGN_IN_WITH_XERO == 'true' && (
              <>
                <span className='d-flex divider my-3' data-label='or'></span>
                <span className='d-flex justify-content-center m-auto'>
                  <iframe
                    title='Sign up with Xero'
                    src={`/signup-with-xero.html?theme=light&stretch=false&align=center&api=${process.env.REACT_APP_B2C_API_URL}`}
                    style={{ height: 52, margin: -8 }}
                    sandbox='allow-scripts allow-top-navigation'></iframe>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
