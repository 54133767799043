import React, { Fragment, useEffect } from 'react';
import { ApiService } from '../services/apiService';

const api = ApiService.getInstance();

const STATUS_TRANSLATE = {
  active: 'active',
  locked: 'lock',
  archived: 'archive',
  lock: 'locked',
  archive: 'archived',
};

const StatusPopup = (props: any) => {
  const { modalStatus, setModalStatus, statusValue, statusObject, applyStatus, lockOptionEnable } = props;
  // const s = React.useContext(Store);
  // const store = s.store;
  // const dispatch = s.dispatch;
  // const [users, setUsers] = React.useState([]);
  // const [helperTables, setHelperTables] = React.useState([]);
  const [values, setValues] = React.useState<any>({ status: statusValue, statusObject });
  const [statusOptions, setStatusOptions] = React.useState<any>({});

  const getStatuses = async () => {
    const statusesFetched = await api.getStatuses();
    if (statusesFetched && Array.isArray(statusesFetched)) {
      const statusOptionsNew: any = {};
      statusesFetched.forEach((status) => {
        if (status.Name && status.Id) {
          statusOptionsNew[status.Name] = status;
        }
      });

      setStatusOptions(statusOptionsNew);
    }
  };

  useEffect(() => {
    const statusValueTranslated =
      statusValue && STATUS_TRANSLATE[(statusValue as 'active') || 'locked' || 'archived']
        ? STATUS_TRANSLATE[(statusValue as 'active') || 'locked' || 'archived']
        : 'active';
    setValues({ status: statusValueTranslated });
  }, [statusValue]);

  useEffect(() => {
    getStatuses();
  }, []);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  return (
    <div>
      {modalStatus && (
        <div>
          <div className='modal d-block'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content' style={{ zIndex: 1051 }}>
                <div className='modal-header'>
                  <h5 className='modal-title'>Edit Status</h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => {
                      setModalStatus(false);
                      setValues({ status: null });
                    }}
                  />
                </div>
                <div className='modal-body'>
                  <div className='mb-3'>
                    {/* <label className="mt-2">Set Status</label> */}
                    <div className='form-check'>
                      <input
                        autoComplete='off'
                        type='radio'
                        id='active'
                        name='status'
                        className='form-check-input'
                        value={'active'}
                        checked={values.status === 'active' || !values.status}
                        onChange={(e) => {
                          handleChange(e, 'status');
                        }}
                      />
                      <label className='form-check-label' htmlFor={'active'}>
                        Active
                      </label>
                    </div>
                    {lockOptionEnable && (
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='lock'
                          name='status'
                          className='form-check-input'
                          value={'lock'}
                          checked={values.status === 'lock'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'lock'}>
                          Lock
                        </label>
                      </div>
                    )}
                    <div className='form-check'>
                      <input
                        autoComplete='off'
                        type='radio'
                        id='archive'
                        name='status'
                        className='form-check-input'
                        value={'archive'}
                        checked={values.status === 'archive'}
                        onChange={(e) => {
                          handleChange(e, 'status');
                        }}
                      />
                      <label className='form-check-label' htmlFor={'archive'}>
                        Archive
                      </label>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    className='btn btn-success'
                    onClick={() => {
                      const statusId =
                        values.status &&
                          STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive'] &&
                          statusOptions &&
                          statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']] &&
                          statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
                          ? statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
                          : null;

                      applyStatus({ statusName: values.status, statusId });
                      setModalStatus(false);
                      setValues({ status: null });
                    }}>
                    <i className='fa-solid fa-check' />
                    Apply
                  </button>

                  <button
                    className='btn btn-outline-dark'
                    onClick={() => {
                      setValues({ status: null });
                      setModalStatus(false);
                    }}>
                    <i className='fa-solid fa-xmark' />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade show' />
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusPopup;
