import React, { Fragment, useEffect, useState } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const STATUS_TRANSLATE = {
  active: 'active',
  locked: 'lock',
  archived: 'archive',
  lock: 'locked',
  archive: 'archived',
};

const Versions = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const deleteEnabled = store.user && store.user.role && store.user.role.toLowerCase() === 'superadmin' ? true : false;

  const [versions, setVersions] = React.useState([]);
  const [milestones, setMilestones] = React.useState<any>({});
  const [values, setValues] = React.useState<any>({});
  const [modalShowVersionAdd, setModalShowVersionAdd] = React.useState<any>(false);
  const [modalShowVersionEdit, setModalShowVersionEdit] = React.useState<any>(false);
  const [modalShowMilestoneAdd, setModalShowMilestoneAdd] = React.useState<any>(false);
  const [modalShowMilestoneEdit, setModalShowMilestoneEdit] = React.useState<any>(false);

  const getVersions = async () => {
    let versionsFetched = await api.getVersions(store?.organisationSelected?.organisationId);
    if (versionsFetched) {

      // sort versions
      const newValues = { ...values };
      versionsFetched = sortList(versionsFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListVersionsMainDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setVersions(versionsFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_VERSIONS,
        payload: versionsFetched,
      };
      dispatch(storeAction);
    }

    if (versionsFetched && Array.isArray(versionsFetched)) {
      getMilestones(versionsFetched as [{ Id: string }]);
    }
  };

  const getMilestones = async (versions: [{ Id: string }]) => {
    const mileStonesFetched: any = { ...milestones };
    for (let i = 0; i < versions.length; i++) {
      const data = await api.getMilestones(versions[i].Id);
      mileStonesFetched[versions[i].Id] = data;
    }
    setMilestones(mileStonesFetched);
  };

  const getStatuses = async () => {
    const statusesFetched = await api.getStatuses();
    if (statusesFetched && Array.isArray(statusesFetched)) {
      const statusOptions: any = {};
      statusesFetched.forEach((status) => {
        if (status.Name && status.Id) {
          statusOptions[status.Name] = status;
        }
      });

      const newValues = { ...values };
      newValues['statusOptions'] = statusOptions;
      setValues(newValues);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getVersions();
      getStatuses();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Versions',
      });
    })();
  }, [store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const versionAdd = async () => {
    const result = await api.createVersion(values.versionAdd, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs
    getVersions();
  };

  const versionAddMultiple = async () => {
    const versions = values.versionAddMultiple.split('\n');

    let failedItems = '';
    const errors = [];
    for (let i = 0; i < versions.length; i++) {
      const version = versions[i].trim();
      const result = await api.createVersion(version, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs
      if (result && result.statusCode) {
        failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
        failedItems += version;
        result.message && errors.push(result.message);
      }
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['versionAddMultiple'] = failedItems;
      newValues['errors'] = errors;

      setValues(newValues);
    } else {
      getVersions();
      setModalShowVersionAdd(false);
    }
  };

  const milestoneAdd = async (versionId: string) => {
    const result = await api.createMilestone(values['milestoneAdd' + versionId], versionId); // TODO handle multiple orgs
    getMilestones([{ Id: versionId }]);
  };

  const milestoneAddMultiple = async (versionId: string) => {
    const milestones = values.milestoneAddMultiple.split('\n');

    let failedItems = '';
    const errors = [];
    for (let i = 0; i < milestones.length; i++) {
      const milestone = milestones[i].trim();
      const result = await api.createMilestone(milestone, versionId); // TODO handle multiple orgs
      if (result && result.statusCode) {
        failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
        failedItems += milestone;
        result.message && errors.push(result.message);
      }
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['milestoneAddMultiple'] = failedItems;
      newValues['errors'] = errors;

      setValues(newValues);
    } else {
      getMilestones([{ Id: versionId }]);
      setModalShowMilestoneAdd(false);
    }
  };

  const [isActive, setActive] = useState({} as any);
  const toggleClass = (id: string) => {
    const active: any = { ...isActive };
    active[id] = active[id] ? false : true;
    setActive(active);
  };

  const toggleClassActive = (id: string) => {
    const active: any = { ...isActive };
    active[id] = true;
    setActive(active);
  };

  const updateVersion = async () => {
    const statusId =
      values.status &&
        STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive'] &&
        values.statusOptions &&
        values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']] &&
        values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        ? values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        : null;

    const result = await api.updateVersion(values.versionEditName, s.store?.organisationSelected?.organisationId, values.editVersion.Id, statusId); // TODO handle multiple orgs

    let failedItems = '';
    const errors = [];
    if (result && result.statusCode) {
      failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
      failedItems += values.versionEditName;
      result.message && errors.push(result.message);
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['errors'] = errors;
      setValues(newValues);
    } else {
      getVersions();
      setModalShowVersionEdit(false);
    }
  };

  const versionDelete = async (deleteVersionId: string) => {
    const result = await api.deleteVersion('del', s.store?.organisationSelected?.organisationId, deleteVersionId); // TODO handle multiple orgs

    getVersions();
  };

  const updateMilestone = async () => {
    const statusId =
      values.status &&
        STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive'] &&
        values.statusOptions &&
        values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']] &&
        values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        ? values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        : null;

    const result = await api.updateMilestone(
      values.milestoneEditName,
      s.store?.organisationSelected?.organisationId,
      values.editMilestone.Id,
      statusId
    ); // TODO handle multiple orgs

    let failedItems = '';
    const errors = [];
    if (result && result.statusCode) {
      failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
      failedItems += values.milestoneEditName;
      result.message && errors.push(result.message);
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['errors'] = errors;
      setValues(newValues);
    } else {
      getVersions();
      setModalShowMilestoneEdit(false);
    }
  };

  const deleteMilestone = async (milestoneId: string) => {
    const result = await api.deleteMilestone(milestoneId); // TODO handle multiple orgs

    getVersions();
  };

  const addPopup = async () => {
    const newValues = { ...values };

    // newValues.subAreaName = '';
    // newValues.editUserFlag = false;
    newValues.versionAddMultiple = '';
    newValues.errors = null;

    //
    setValues(newValues);
    setModalShowVersionAdd(true);
  };

  const editVersionPopup = async (editVersion: any) => {
    const newValues = { ...values };

    newValues.editVersion = editVersion;
    newValues.versionEditName = editVersion.Name;
    newValues.errors = null;
    newValues.status =
      editVersion.Status && editVersion.Status.Name && STATUS_TRANSLATE[(editVersion.Status.Name as 'active') || 'locked' || 'archived']
        ? STATUS_TRANSLATE[(editVersion.Status.Name as 'active') || 'locked' || 'archived']
        : STATUS_TRANSLATE['active'];

    //
    setValues(newValues);
    setModalShowVersionEdit(true);
  };

  const addMilestonePopup = async (version: any) => {
    const newValues = { ...values };

    newValues.milestoneAddMultiple = '';
    newValues.milestoneAddMultipleVersion = version;
    newValues.errors = null;

    //
    setValues(newValues);
    setModalShowMilestoneAdd(true);
  };

  const editMilestonePopup = async (milestone: any) => {
    const newValues = { ...values };

    newValues.editMilestone = milestone;
    newValues.milestoneEditName = milestone.Name;
    newValues.errors = null;
    newValues.status =
      milestone.Status && milestone.Status.Name && STATUS_TRANSLATE[(milestone.Status.Name as 'active') || 'locked' || 'archived']
        ? STATUS_TRANSLATE[(milestone.Status.Name as 'active') || 'locked' || 'archived']
        : STATUS_TRANSLATE['active'];

    setValues(newValues);
    setModalShowMilestoneEdit(true);
  };

  const sortListVersions = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(versions, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListVersionsMainDirection = sortDirection;
    setValues(newValues);
    setVersions(sortedList as any);
  };

  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-11'>
          <h3 className='page-heading has-tools mb-4'>
            Versions
            <div className='tools'>
              <button
                className='btn btn-secondary fw-bold'
                onClick={() => {
                  addPopup();
                }}>
                <i className='fa-solid fa-plus' />
                Add new Version
              </button>
            </div>
          </h3>

          <div className='card card--height-limit'>
            <table className='table table--no-head-border'>
              <colgroup>
                <col style={{ width: '30%' }} />
                <col style={{ width: '19%' }} />
                <col style={{ width: '19%' }} />
                <col style={{ width: '7%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
              <thead>
                <tr className='table-header'>
                  <th style={{cursor: 'pointer'}}>
                    <div style={{display: 'inline-block'}}
                      onClick={(e) => {
                        sortListVersions(values.sortListVersionsMainDirection === undefined || values.sortListVersionsMainDirection === SORT_DIRECTION.ASC
                          ? SORT_DIRECTION.DEC 
                          : SORT_DIRECTION.ASC
                        );
                      }}>
                      Name 
                      <i className={values.sortListVersionsMainDirection === undefined || values.sortListVersionsMainDirection === SORT_DIRECTION.ASC 
                        ? 'fa-solid fa-arrow-down-short-wide ms-1'
                        : 'fa-solid fa-arrow-up-wide-short ms-1'
                      } />
                    </div>
                    <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        id='filterVersion'
                        name='filterVersion'
                        placeholder='Search'
                        value={values.filterVersion}
                        onChange={(e) => handleChange(e, 'filterVersion')}
                      />
                    </div>
                  </th>
                  <th>Excel Table Name</th>
                  <th>SQL Table Name</th>
                  <th>Status</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {versions.map((version: any, index) => {
                  if (values.filterVersion && values.filterVersion !== '' && version.Name.toLowerCase().indexOf(values.filterVersion.toLowerCase()) === -1) {
                    return <Fragment></Fragment>;
                  }
                  return (
                    <React.Fragment key={index}>
                      <tr className={isActive && version.Id && isActive[version.Id] ? 'version version--open' : 'version version--closed'}>
                        <td>
                          <span className='version--toggle version--open'>
                            <i
                              className={isActive && version.Id && isActive[version.Id] ? 'fa-solid fa-square-minus' : 'fa-solid fa-square-plus'}
                              onClick={() => toggleClass(version.Id)}
                            />
                            {version.Name}{' '}
                            <span className='milestone__counter'>
                              {milestones[version.Id] && Array.isArray(milestones[version.Id]) ? milestones[version.Id].length : 0}
                            </span>
                          </span>
                        </td>
                        <td>
                          {(() => {
                            let name = version.Name.trim().replace(/ /g, '');
                            if (name.length > 20) {
                              name = `${name.slice(0, 20)}...`;
                            }
                            return `${name}SQLLoader`;
                          })()}
                        </td>
                        <td>
                          {(() => {
                            let name = version.Name.trim().replace(/ /g, '');
                            if (name.length > 20) {
                              name = `${name.slice(0, 20)}...`;
                            }
                            return `B2S${name}`;
                          })()}
                        </td>
                        <td>
                          <div className='tools'>
                            {(() => {
                              if (version.Status && version.Status.Name === 'active') {
                                return <span className='status-label active'>ACTIVE</span>;
                              } else if (version.Status && version.Status.Name === 'locked') {
                                return <span className='status-label locked'>LOCKED</span>;
                              } else if (version.Status && version.Status.Name === 'archived') {
                                return <span className='status-label archived'>ARCHIVED</span>;
                              } else {
                                return <span className='status-label active'>ACTIVE</span>;
                              }
                            })()}
                          </div>
                        </td>
                        <td>
                          <div className='tools justify-content-end'>
                            <button
                              className='btn btn-outline-secondary btn-sm'
                              onClick={() => {
                                toggleClassActive(version.Id);
                                addMilestonePopup(version);
                              }}>
                              <i className='fa-solid fa-plus' />
                              Add Milestone
                            </button>
                            <button
                              className='btn btn-outline-primary btn-sm'
                              onClick={() => {
                                editVersionPopup(version);
                              }}>
                              <i className='fa-solid fa-pen-to-square' />
                              Edit
                            </button>
                            {deleteEnabled && (
                              <button
                                className='btn btn-outline-danger btn-sm'
                                onClick={() => {
                                  versionDelete(version.Id);
                                }}>
                                <i className='fa-solid fa-trash-can' />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className='p-0'>
                          <div
                            className={
                              isActive && version.Id && isActive[version.Id]
                                ? 'card card--milestone milestone version--opened'
                                : 'card card--milestone milestone version--closed'
                            }>
                            <table className='table table--no-head-border'>
                              <colgroup>
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '19%' }} />
                                <col style={{ width: '19%' }} />
                                <col style={{ width: '17%' }} />
                                <col style={{ width: '25%' }} />
                              </colgroup>

                              <tbody>
                                {milestones[version.Id] &&
                                  Array.isArray(milestones[version.Id]) &&
                                  milestones[version.Id].map((milestone: any, index: number) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <span>{milestone.Name}</span>
                                        </td>

                                        <td></td>
                                        <td></td>

                                        <td>
                                          <div className='tools'>
                                            {(() => {
                                              if (milestone.Status && milestone.Status.Name === 'active') {
                                                return <span className='status-label active'>ACTIVE</span>;
                                              } else if (milestone.Status && milestone.Status.Name === 'locked') {
                                                return <span className='status-label locked'>LOCKED</span>;
                                              } else if (milestone.Status && milestone.Status.Name === 'archived') {
                                                return <span className='status-label archived'>ARCHIVED</span>;
                                              } else {
                                                return <span className='status-label active'>ACTIVE</span>;
                                              }
                                            })()}
                                          </div>
                                        </td>
                                        <td>
                                          <div className='tools justify-content-end'>
                                            <button
                                              className='btn btn-outline-primary btn-sm'
                                              onClick={() => {
                                                editMilestonePopup(milestone);
                                              }}>
                                              <i className='fa-solid fa-pen-to-square' />
                                              Edit
                                            </button>
                                            {deleteEnabled && (
                                              <button
                                                className='btn btn-outline-danger btn-sm'
                                                onClick={() => {
                                                  deleteMilestone(milestone.Id);
                                                }}>
                                                <i className='fa-solid fa-trash-can' />
                                              </button>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {modalShowVersionAdd && (
        <div className='modal d-block'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content' style={{ zIndex: 1051 }}>
              <div className='modal-header'>
                <h5 className='modal-title'>Add new Version(s)</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    setModalShowVersionAdd(false);
                  }}
                />
              </div>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='areaName' className='label--has-info'>
                    Name(s)
                    <small className='alert alert-success p-2'>
                      <i className='fa-solid fa-circle-info me-2' />
                      Add multiple items by separating by a new line
                    </small>
                  </label>
                  <textarea
                    className='form-control'
                    id='versionAddMultiple'
                    name='versionAddMultiple'
                    rows={6}
                    placeholder='Add Version'
                    onChange={(e) => handleChange(e, 'versionAddMultiple')}
                    value={values.versionAddMultiple}
                  />
                  {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                    <div className='mt-2'>
                      <label className='label--has-info'>
                        <small className='alert alert-danger p-2'>
                          <i className='fa-solid fa-exclamation-circle me-2' />
                          <div className='float-end'>
                            {values.errors.map((message: any) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        </small>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-success'
                  onClick={() => {
                    versionAddMultiple();
                  }}>
                  <i className='fa-solid fa-check' />
                  Add
                </button>
                <button
                  className='btn btn-outline-dark'
                  onClick={() => {
                    setModalShowVersionAdd(false);
                  }}>
                  <i className='fa-solid fa-xmark' />
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show' />
        </div>
      )}

      {modalShowVersionEdit && (
        <div className='modal d-block'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content' style={{ zIndex: 1051 }}>
              <div className='modal-header'>
                <h5 className='modal-title'>Edit Version</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    setModalShowVersionEdit(false);
                  }}
                />
              </div>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='areaName'>Name</label>
                  <input
                    className='form-control'
                    type='text'
                    id='versionAdd'
                    name='versionAdd'
                    placeholder='Edit Version'
                    value={values.versionEditName}
                    onChange={(e) => handleChange(e, 'versionEditName')}
                  />
                  {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                    <div className='mt-2'>
                      <label className='label--has-info'>
                        <small className='alert alert-danger p-2'>
                          <i className='fa-solid fa-exclamation-circle me-2' />
                          <div className='float-end'>
                            {values.errors.map((message: any) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        </small>
                      </label>
                    </div>
                  )}
                  <label className='mt-2'>Set Status</label>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='active'
                      name='status'
                      className='form-check-input'
                      value={'active'}
                      checked={values.status === 'active'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'active'}>
                      Active
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='lock'
                      name='status'
                      className='form-check-input'
                      value={'lock'}
                      checked={values.status === 'lock'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'lock'}>
                      Lock
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='archive'
                      name='status'
                      className='form-check-input'
                      value={'archive'}
                      checked={values.status === 'archive'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'archive'}>
                      Archive
                    </label>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-success'
                  onClick={() => {
                    updateVersion();
                  }}>
                  <i className='fa-solid fa-check' />
                  Apply
                </button>
                <button
                  className='btn btn-outline-dark'
                  onClick={() => {
                    setModalShowVersionEdit(false);
                  }}>
                  <i className='fa-solid fa-xmark' />
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show' />
        </div>
      )}

      {modalShowMilestoneAdd && (
        <div className='modal d-block'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content' style={{ zIndex: 1051 }}>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Milestone</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    setModalShowMilestoneAdd(false);
                  }}
                />
              </div>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='milestoneName' className='label--has-info'>
                    Name(s)
                    <small className='alert alert-success p-2'>
                      <i className='fa-solid fa-circle-info me-2' />
                      Add multiple items by separating by a new line
                    </small>
                  </label>
                  <textarea
                    className='form-control'
                    rows={6}
                    name='milestoneName'
                    placeholder='Milestone name'
                    onChange={(e) => handleChange(e, 'milestoneAddMultiple')}
                    value={values.milestoneAddMultiple}
                  />
                  {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                    <div className='mt-2'>
                      <label className='label--has-info'>
                        <small className='alert alert-danger p-2'>
                          <i className='fa-solid fa-exclamation-circle me-2' />
                          <div className='float-end'>
                            {values.errors.map((message: any) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        </small>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <button className='btn btn-success btn-sm' onClick={() => milestoneAddMultiple(values.milestoneAddMultipleVersion.Id)}>
                  <i className='fa-solid fa-check' />
                  Add
                </button>
                <button
                  className='btn btn-outline-dark btn-sm'
                  onClick={() => {
                    setModalShowMilestoneAdd(false);
                  }}>
                  <i className='fa-solid fa-xmark' />
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show' />
        </div>
      )}

      {modalShowMilestoneEdit && (
        <div className='modal d-block'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content' style={{ zIndex: 1051 }}>
              <div className='modal-header'>
                <h5 className='modal-title'>Edit Milestone</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    setModalShowMilestoneEdit(false);
                  }}
                />
              </div>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='milestoneName' className='label--has-info'>
                    Name
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    name={'milestoneEditName'}
                    placeholder='Milestone name'
                    value={values.milestoneEditName}
                    onChange={(e) => handleChange(e, 'milestoneEditName')}
                  />
                  {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                    <div className='mt-2'>
                      <label className='label--has-info'>
                        <small className='alert alert-danger p-2'>
                          <i className='fa-solid fa-exclamation-circle me-2' />
                          <div className='float-end'>
                            {values.errors.map((message: any) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        </small>
                      </label>
                    </div>
                  )}
                  <label className='mt-2'>Set Status</label>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='active'
                      name='status'
                      className='form-check-input'
                      value={'active'}
                      checked={values.status === 'active'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'active'}>
                      Active
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='lock'
                      name='status'
                      className='form-check-input'
                      value={'lock'}
                      checked={values.status === 'lock'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'lock'}>
                      Lock
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      autoComplete='off'
                      type='radio'
                      id='archive'
                      name='status'
                      className='form-check-input'
                      value={'archive'}
                      checked={values.status === 'archive'}
                      onChange={(e) => {
                        handleChange(e, 'status');
                      }}
                    />
                    <label className='form-check-label' htmlFor={'archive'}>
                      Archive
                    </label>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-success btn-sm'
                  onClick={() => {
                    updateMilestone();
                  }}>
                  <i className='fa-solid fa-check' />
                  Apply
                </button>
                <button
                  className='btn btn-outline-dark btn-sm'
                  onClick={() => {
                    setModalShowMilestoneEdit(false);
                  }}>
                  <i className='fa-solid fa-xmark' />
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show' />
        </div>
      )}
    </div>
  );
};

export default withRouter(Versions);
