import HomeConnectorCard from './HomeConnectorCard';
import React, { useEffect, useState } from 'react';

import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { PAGE_URLS } from '../routes/routes';

const Home = () => {
  const store = React.useContext(Store).store;

  const [showLoader, setShowLoader] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(store?.organisationSelected?.organisationId);

  useEffect(() => {
    setSelectedOrganisation(store?.organisationSelected?.organisationId);
    setShowLoader(true);
  }, [store?.organisationSelected?.organisationId]);

  useEffect(() => {
    setShowLoader(true);
  }, [store?.connectorTypeCacheBust]);

  useEffect(() => {
    if (store?.organisationConnectorTypes?.length || store?.connectorTypes?.length) setShowLoader(false);
  }, [store?.organisationConnectorTypes, store?.connectorTypes]);

  return (
    <div>
      {!store?.organisationConnectorTypes ? (
        <>
          <h4 className='page-sub-heading'></h4>
          <div className='grid grid-col-5 grid-skeleton'>
            <HomeConnectorCard skeleton={true} connectorType={{}} />
            <HomeConnectorCard skeleton={true} connectorType={{}} />
            <HomeConnectorCard skeleton={true} connectorType={{}} />
            <HomeConnectorCard skeleton={true} connectorType={{}} />
          </div>
        </>
      ) : (
        <>
          {store?.organisationConnectorTypes.length > 0 && (
            <>
              <h4 className='page-sub-heading'>Your connectors</h4>
              <div className='grid grid-col-5'>
                {store?.organisationConnectorTypes?.map((connectorType: any) =>
                  connectorType.Type.toLowerCase() === 'excel' && store?.user?.roleLevel <= 3 ? (
                    <HomeConnectorCard key={connectorType.Id} connectorType={connectorType} to={PAGE_URLS.EXCEL_AREAS} />
                  ) : (
                    <HomeConnectorCard key={connectorType.Id} connectorType={connectorType} />
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
      {store?.connectorTypes?.filter(
        (connectorType: any) =>
          store?.organisationConnectorTypes?.findIndex((organisationConnectorType: any) => organisationConnectorType.Id == connectorType.Id) == -1
      ).length > 0 && (
        <>
          {store?.organisationConnectorTypes.length > 0 && <div className='page-divider'></div>}
          <h4 className='page-sub-heading'>Available connectors</h4>
          <div className='grid grid-col-5 home_available-connectors'>
            {store?.connectorTypes
              ?.filter(
                (connectorType: any) =>
                  store?.organisationConnectorTypes?.findIndex(
                    (organisationConnectorType: any) => organisationConnectorType.Id == connectorType.Id
                  ) == -1
              )
              .map((connectorType: any) =>
                connectorType.Type.toLowerCase() === 'excel' && store?.user?.roleLevel <= 3 ? (
                  <HomeConnectorCard key={connectorType.Id} connectorType={connectorType} to={PAGE_URLS.EXCEL_AREAS} />
                ) : (
                  <HomeConnectorCard
                    key={connectorType.Id}
                    connectorType={connectorType}
                    to={
                      connectorType.RequiresAuthorisation
                        ? `${process.env.REACT_APP_B2C_API_URL}/api/authorise/${connectorType.Type.toLowerCase()}?state=${
                            store?.organisationSelected?.organisationId
                          }`
                        : `${PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(':connectorType', connectorType.Type.toLowerCase())}?new=true`
                    }
                  />
                )
              )}
          </div>
        </>
      )}
      {showLoader && (
        <div className='dashboard__content__loader'>
          <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
            <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      )}
    </div>
  );
};

export default withRouter(Home);
