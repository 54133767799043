import React, { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Store } from '../store/Store';
import { REDUCER_ACTION_SET } from '../store/types';
import { PAGE_URLS } from '../routes/routes';
import { ApiService } from '../services/apiService';
import { withRouter } from '../routes/withRouter';
import { debounce } from 'lodash';
import Business2CloudApiService from '../services/Business2CloudApiService';

const Nav = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { children } = props;
  const { connectorType: currentConnectorType } = useParams();

  const store = useContext(Store).store;
  const dispatch = useContext(Store).dispatch;
  const userName = store.user ? `${store.user.firstName} ${store.user.lastName}` : '';
  const userNameInitials = store?.user?.firstName?.charAt(0) + '' + store?.user?.lastName?.charAt(0);

  const [organisations, setOrganisations] = useState(store.organisations ? store.organisations : []);
  const [toggleNavigation, setToggleNavigation] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [organisationSelected, setOrganisationSelected] = useState<any>(store?.organisationSelected?.data);
  const [showConnectorSkeleton, setShowConnectorSkeleton] = useState(true);

  const location = useLocation();

  const handleResize = () => {
    if (window.innerWidth < 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!organisationSelected) return;

    Business2CloudApiService.Instance.getOrganisationConnectorTypesAsync(organisationSelected.Id).then((response: any) => {
      dispatch({
        type: REDUCER_ACTION_SET.SET_ORGANISATION_CONNECTOR_TYPES,
        payload: response,
      });
    });
    Business2CloudApiService.Instance.getConnectorTypesAsync().then((response: any) => {
      dispatch({
        type: REDUCER_ACTION_SET.SET_CONNECTOR_TYPES,
        payload: response,
      });
    });
  }, [organisationSelected, store?.connectorTypeCacheBust]);

  useEffect(() => {
    if (store?.organisationConnectorTypes) setShowConnectorSkeleton(false);
  }, [store?.organisationConnectorTypes]);

  useEffect(() => {
    if (isMobile) {
      setToggleNavigation(false);
    }
  }, [location, organisationSelected]);

  useEffect(() => {
    handleResize();

    if (window.innerWidth >= 575) {
      const _toggleNavigation = document.cookie
        .split('; ')
        .find((_) => _.startsWith('nav='))
        ?.split('=')[1];

      setToggleNavigation(_toggleNavigation === 'true');
    } else {
      setToggleNavigation(false);
    }

    setTimeout(() => setAnimate(true), 500);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      document.cookie = `nav=${toggleNavigation}`;
    }
  }, [toggleNavigation]);

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      let organisationsFetched = await ApiService.getInstance().getOrganisations();
      let organisation = searchParams.get('organisation');
      let selectedOrganisation = !!organisation
        ? organisationsFetched.find((_: any) => _.Id.toLowerCase() == organisation?.toLowerCase())
        : store?.organisationSelected?.data || organisationsFetched.find((_: any) => true);

      setOrganisations(organisationsFetched);
      setOrganisationSelected(selectedOrganisation);

      dispatch({
        type: REDUCER_ACTION_SET.SET_ORGANISATIONS,
        payload: organisationsFetched,
      });

      dispatch({
        type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
        payload: {
          organisationId: selectedOrganisation.Id,
          data: selectedOrganisation,
        },
      });
    })();
  }, []);

  if (
    organisations &&
    Array.isArray(organisations) &&
    organisations.length === 0 &&
    store.organisations &&
    Array.isArray(store.organisations) &&
    store.organisations.length > 0
  ) {
    setOrganisations(store.organisations);
    const storeAction = {
      type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
      payload: {
        organisationId: store.organisations[0].Id,
        data: store.organisations[0],
      },
    };
    dispatch(storeAction);

    setOrganisationSelected({
      ...organisationSelected,
      activeOrganisationIndex: 0,
    });
  }

  const handleChangeOrganisation = (e: ChangeEvent<HTMLSelectElement>, name: string) => {
    let selectedOrganisation = organisations.find((_: any) => _.Id.toLowerCase() === e.target.value.toLowerCase());

    setOrganisationSelected(selectedOrganisation);

    dispatch({
      type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
      payload: {
        organisationId: selectedOrganisation.Id,
        data: selectedOrganisation,
      },
    });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set('search', event.target.value.toLowerCase());
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };

  const onSearchChangeDebounce = debounce(onSearchChange, 300);

  return (
    <div className='dashboard'>
      <nav className={`nav__sidebar ${toggleNavigation ? '' : 'minimised'} ${animate ? 'animate' : ''}`}>
        <div className='nav__sidebar-header'>
          <NavLink to={PAGE_URLS.PROFILE} className={`nav__username ${location.pathname.indexOf('/profile') >= 0 ? 'active' : ''}`}>
            <div className='nav__username-initial' title={userName}>
              {userNameInitials}
            </div>
            <div className='d-flex flex-column' style={{ overflow: 'hidden' }}>
              {store.user.firstName}
              <small className='d-inline-block' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {store.user.email}
              </small>
            </div>
          </NavLink>
          <div className='nav__toggle ms-auto' onClick={() => setToggleNavigation(!toggleNavigation)}>
            <span className='material-icons-two-tone'>{toggleNavigation ? 'menu_open' : 'menu'}</span>
          </div>
        </div>
        <div className='nav__sidebar-content'>
          <div className='nav__sidebar-sticky'>
            <NavLink to={PAGE_URLS.PROFILE} className={`nav__username ${location.pathname.indexOf('/profile') >= 0 ? 'active' : ''}`}>
              <div className='nav__username-initial' title={userName}>
                {userNameInitials}
              </div>
              <div className='d-flex flex-column' style={{ overflow: 'hidden' }}>
                {store.user.firstName}
                <small className='d-inline-block' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {store.user.email}
                </small>
              </div>
            </NavLink>
            <div className='nav__organisation' title='Organisation'>
              <span className='nav__organisation-initial'>{organisationSelected?.CompanyName?.charAt(0)}</span>
              <select
                id='selectRole'
                className='form-select'
                defaultValue={organisationSelected?.Id}
                onChange={(e) => handleChangeOrganisation(e, 'activeOrganisationIndex')}>
                {organisations &&
                  organisations
                    .sort((a: any, b: any) => a.CompanyName.localeCompare(b.CompanyName))
                    .map((organisation: any, index: number) => {
                      return (
                        <option key={index} value={organisation.Id}>
                          {organisation.CompanyName}
                        </option>
                      );
                    })}
              </select>
            </div>
            <div className='nav__divider_line'></div>
            <NavLink to={PAGE_URLS.HOME} className='nav__link nav__link--main'>
              <span className='material-icons-two-tone'>home</span>
              Home
            </NavLink>
            {store?.user?.roleLevel <= 2 && (
              <>
                <NavLink
                  to={store.user.roleLevel <= 1 ? PAGE_URLS.ADMIN_DASHBOARD : PAGE_URLS.ADMIN_USERS}
                  className={`nav__link nav__link--main ${location.pathname.indexOf('/admin') >= 0 ? 'active' : ''}`}>
                  <span className='material-icons-two-tone'>manage_accounts</span>
                  Admin
                </NavLink>
              </>
            )}
            {store?.organisationConnectorTypes?.length > 0 && (
              <>
                <div className='nav__divider_line'></div>
                <div className='nav__divider'>connectors</div>
              </>
            )}
          </div>
          {showConnectorSkeleton ? (
            <div className='nav__link--skeleton-wrapper'>
              <div className='text-left justify-content-start nav__link nav__link--skeleton nav__link--main'></div>
              <div className='text-left justify-content-start nav__link nav__link--skeleton nav__link--main'></div>
              <div className='text-left justify-content-start nav__link nav__link--skeleton nav__link--main'></div>
              <div className='text-left justify-content-start nav__link nav__link--skeleton nav__link--main'></div>
            </div>
          ) : (
            <>
              {store?.organisationConnectorTypes?.map(
                (connectorType: any) =>
                  (connectorType.Type.toLowerCase() != 'excel' || store?.user?.roleLevel <= 3) && (
                    <NavLink
                      key={connectorType.Id}
                      to={
                        connectorType.Type.toLowerCase() === 'excel'
                          ? PAGE_URLS.EXCEL_AREAS
                          : PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', connectorType.Type.toLowerCase())
                      }
                      className={`text-left justify-content-start nav__link nav__link--main ${
                        location.pathname.indexOf(`/${connectorType.Type.toLowerCase()}`) >= 0 ? 'active' : ''
                      }`}>
                      <img
                        style={{ maxHeight: 24 }}
                        src={`/img/mini-connector-logo-${connectorType.Type.toLowerCase()}.svg`}
                        alt={`${connectorType.Type} Connector`}
                      />
                      {connectorType.Type}
                    </NavLink>
                  )
              )}
            </>
          )}
        </div>
        <div className='nav__sidebar-footer mt-auto'>
          <div className='nav__logo'>
            <img src='/img/b2c-logo-dark.svg' alt='' />
          </div>
          <NavLink
            title='Logout'
            to='/'
            className='btn-secondary btn-round btn-sm nav__username-logout ms-auto'
            onClick={() => {
              localStorage.setItem('cachedData', '');
              localStorage.setItem('cachedDataStore', '');
            }}>
            <span className='material-icons-two-tone'>logout</span>
          </NavLink>
        </div>
      </nav>
      <div className='dashboard__main'>
        <nav className='nav__top'>
          {location.pathname.indexOf(PAGE_URLS.PROFILE) >= 0 && (
            <>
              <h3 className='nav__top-title'>Profile</h3>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.ADMIN) >= 0 && (
            <>
              <h3 className='nav__top-title'>
                Admin
                {location.pathname.indexOf(PAGE_URLS.ADMIN_USERS) >= 0 && (
                  <input type='text' className='form-control page-search' placeholder='search' onChange={onSearchChangeDebounce} />
                )}
              </h3>
              <div className='nav__top-links'>
                {store?.user?.roleLevel <= 1 && <NavLink to={PAGE_URLS.ADMIN_DASHBOARD}>Dashboard</NavLink>}
                {store?.user?.roleLevel <= 1 && <NavLink to={PAGE_URLS.ADMIN_CONFIGURATION}>Global Configuration</NavLink>}
                <NavLink to={PAGE_URLS.ADMIN_USERS}>Users</NavLink>
                <NavLink to={PAGE_URLS.ADMIN_APPROVALS}>Approvals</NavLink>
              </div>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.EXCEL) >= 0 && (
            <>
              <h3 className='nav__top-title'>Excel Connector</h3>
              <div className='nav__top-links'>
                <NavLink to={PAGE_URLS.EXCEL_AREAS}>Areas</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_SUBAREAS}>Subareas</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_VERSIONS}>Versions</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_HELPER_TABLES}>Helper Tables</NavLink>
                {store?.user?.roleLevel <= 2 && <NavLink to={PAGE_URLS.EXCEL_TABLE_SYNCHRONISATION}>Configure</NavLink>}
                <NavLink to={PAGE_URLS.EXCEL_SECURITY_GROUPS} className={location.pathname.indexOf('/excel/security') >= 0 ? 'active' : ''}>
                  Security
                </NavLink>
              </div>
              {(location.pathname.indexOf(PAGE_URLS.EXCEL_SUBAREAS) >= 0 || location.pathname.indexOf(PAGE_URLS.EXCEL_AREAS) >= 0) && (
                <div className='nav__top-sublinks'>
                  <NavLink to={PAGE_URLS.EXCEL_AREA_SUBAREAS}>Link</NavLink>
                </div>
              )}
              {location.pathname.indexOf('/excel/security') >= 0 && (
                <div className='nav__top-sublinks'>
                  <NavLink to={PAGE_URLS.EXCEL_SECURITY_GROUPS}>Groups</NavLink>
                  <NavLink to={PAGE_URLS.EXCEL_USER_SECURITY_GROUPS}>User groups</NavLink>
                  <NavLink to={PAGE_URLS.EXCEL_USER_HELPER_TABLES}>User helper tables</NavLink>
                </div>
              )}
            </>
          )}
          {currentConnectorType && currentConnectorType !== 'excel' && (
            <>
              <h3 className='nav__top-title'>
                {currentConnectorType?.replace('server', ' server')} Connectors
                <input type='text' className='form-control page-search' placeholder='search' onChange={onSearchChangeDebounce} />
              </h3>
              <div className='nav__top-links'>
                <NavLink to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', currentConnectorType)}>Refresh</NavLink>
                {store?.user?.role?.toLowerCase().indexOf('admin') >= 0 && (
                  <NavLink to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(':connectorType', currentConnectorType)}>Manage</NavLink>
                )}
                {currentConnectorType === 'xero' && store?.user?.role?.toLowerCase().indexOf('superadmin') >= 0 && (
                  <>
                    <NavLink to={PAGE_URLS.XERO_V2_SYNCHRONISE.replace(':connectorType', currentConnectorType)}>Refresh V2</NavLink>
                    <NavLink to={PAGE_URLS.XERO_V2_ASSIGN_USERS.replace(':connectorType', currentConnectorType)}>Configure V2</NavLink>
                  </>
                )}
              </div>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.HOME) >= 0 && (
            <>
              <h3 className='nav__top-title'>Home</h3>
            </>
          )}
        </nav>
        <div className='dashboard__content'>{children}</div>
      </div>
    </div>
  );
};

export default withRouter(Nav);
