import React, { useEffect, useRef } from 'react';
import { withRouter } from '../routes/withRouter';
import { PAGE_URLS } from '../routes/routes';
import { ApiService } from '../services/apiService';

const api = ApiService.getInstance();

const Tokens = (props: any) => {
  // const s = React.useContext(Store);
  // const store = s.store;
  // const dispatch = s.dispatch;

  const [tokens, setTokens] = React.useState<any[] | undefined>();
  const [creds, setCreds] = React.useState<any[] | undefined>();
  const [currentToken, setCurrentToken] = React.useState('');
  const [currentTenant, setCurrentTenant] = React.useState('');
  const [sqlCreds, setSqlCreds] = React.useState<any>({});
  const orgNameRef = useRef<HTMLInputElement>(null);
  const hostRef = useRef<HTMLInputElement>(null);
  const databaseRef = useRef<HTMLInputElement>(null);
  const schemaRef = useRef<HTMLInputElement>(null);
  const userRef = useRef<HTMLInputElement>(null);

  const getTokens = async () => {
    const tokensFetched = await api.getXero2SqlTokens();
    if (tokensFetched) {
      setTokens(tokensFetched);
    }
  };

  const getTokenCreds = async (id: string) => {
    const credsFetched = await api.getXero2SqlToken(id);
    if (credsFetched) {
      setCurrentToken(id);
      setCreds(credsFetched);
    }
  };

  const renderInputForm = () => {
    return (
      <div hidden={!currentTenant.length}>
        <input
          type='text'
          id='orgName'
          ref={orgNameRef}
          placeholder='Organisation Name'
          onInput={(e) => setSqlCreds({ ...sqlCreds, orgName: e.currentTarget.value })}
        />
        <br />
        <input type='text' id='host' ref={hostRef} placeholder='Host' onInput={(e) => setSqlCreds({ ...sqlCreds, host: e.currentTarget.value })} />
        <br />
        <input
          type='text'
          id='database'
          ref={databaseRef}
          placeholder='Database Name'
          onInput={(e) => setSqlCreds({ ...sqlCreds, database: e.currentTarget.value })}
        />
        <br />
        <input
          type='text'
          id='schema'
          ref={schemaRef}
          placeholder='Schema'
          onInput={(e) => setSqlCreds({ ...sqlCreds, schema: e.currentTarget.value })}
        />
        <br />
        <input type='password' id='password' placeholder='Password' onInput={(e) => setSqlCreds({ ...sqlCreds, password: e.currentTarget.value })} />
        <br />
        <input
          type='password'
          id='confirmPassword'
          placeholder='Confirm Password'
          onInput={(e) => setSqlCreds({ ...sqlCreds, confirmPassword: e.currentTarget.value })}
        />
        <br />
        <input type='text' id='user' ref={userRef} placeholder='User' onInput={(e) => setSqlCreds({ ...sqlCreds, user: e.currentTarget.value })} />
        <br />
        <button
          disabled={
            !sqlCreds.orgName?.length ||
            !sqlCreds.host?.length ||
            !sqlCreds.database?.length ||
            !sqlCreds.schema?.length ||
            !sqlCreds.password?.length ||
            !sqlCreds.user?.length ||
            sqlCreds.password !== sqlCreds.confirmPassword
          }
          onClick={async () => {
            try {
              await api.setXero2SqlTokenCreds(currentToken, {
                tenantId: currentTenant,
                tenantName: sqlCreds.orgName,
                creds: {
                  ...sqlCreds,
                  connector: 'mssql',
                  confirmPassword: undefined,
                  orgName: undefined,
                },
              });
              alert('Success!');
              props.history.push(PAGE_URLS.EXCEL_ENTITIES);
              // reset();
            } catch (err) {
              alert(err);
            }
          }}>
          Submit
        </button>
      </div>
    );
  };

  const renderTokens = () => {
    if (!tokens) {
      return <h3>No tokens fetched</h3>;
    } else if (!tokens.length) {
      return <h3>No tokens present</h3>;
    } else {
      return (
        <React.Fragment>
          <br />
          <ul>
            {tokens.map((t) => (
              <li
                id={t.xero_userid}
                onClick={(e) => {
                  getTokenCreds(e.currentTarget.id);
                  setCurrentTenant('');
                  setSqlCreds({});
                }}>
                {t.email} ({t.xero_userid})
                {currentToken === t.xero_userid ? (
                  <ul>
                    {creds?.map((c) => (
                      <li
                        id={c.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          const orgCreds = creds.find((cr) => c.id === cr.id);
                          setSqlCreds({ orgName: orgCreds.name, ...orgCreds.creds });
                          setCurrentTenant(e.currentTarget.id);
                          orgNameRef.current?.setAttribute('value', c.name);
                          hostRef.current?.setAttribute('value', orgCreds.creds.host);
                          databaseRef.current?.setAttribute('value', orgCreds.creds.database);
                          schemaRef.current?.setAttribute('value', orgCreds.creds.schema);
                          userRef.current?.setAttribute('value', orgCreds.creds.user);
                        }}>
                        {c.name} ({c.id})
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getTokens();
    })();
  }, []);

  return (
    <div>
      <div>{renderTokens()}</div>
      <div>{renderInputForm()}</div>
    </div>
  );
};

export default withRouter(Tokens);
